import {
  Grid,
  List,
  ListItem,
  Typography,
} from '@mui/material';

function LabelData ({ label, data }) {
  return (
    <Grid container spacing={2} sx={{ ml: 2 }}>
      <Grid item xs={4}>
        <Typography variant="overline">{label}</Typography>
      </Grid>
      <Grid item xs={8}>
        <Typography variant="subtitle1">{data}</Typography>
      </Grid>
    </Grid>
  );
}

const labelMapWho = {
  role: 'Role',
  customerID: 'Customer',
};

const labelMapWhat = { typeName: 'Type' };

function labelMapCanHaz (rawLabel) {
  if (rawLabel.includes('_')) {
    const label = rawLabel
      .split('_')
      // uppercase https://stackoverflow.com/questions/1026069/how-do-i-make-the-first-letter-of-a-string-uppercase-in-javascript
      .map((item) => item.charAt(0).toUpperCase() + item.slice(1))
      .join(' ');
    return label;
  }
  const label = rawLabel.charAt(0).toUpperCase() + rawLabel.slice(1);
  return label;
}

function InfoList ({ formState, customer }) {
  if (!formState || !customer) return null;

  return (
    <List>
      <ListItem><Typography variant="h6">Who</Typography></ListItem>
      {Object.entries(formState?.who).map(([key, data]) => {
        if (key === 'customerID') {
          return (
            <ListItem key={`list-item-${key}`}>
              <LabelData key={`label-data-${key}`} label={labelMapWho[key]} data={customer.customerName} />
            </ListItem>
          );
        }
        return (
          <ListItem key={`list-item-${key}`}>
            <LabelData key={`label-data-${key}`} label={labelMapWho[key]} data={data} />
          </ListItem>
        );
      })}
      <ListItem><Typography variant="h6">What</Typography></ListItem>
      <ListItem>
        <LabelData label={labelMapWhat.typeName} data={formState?.what.typeName} />
      </ListItem>
      <ListItem><Typography variant="h6">Can Haz</Typography></ListItem>
      {formState?.canHaz.map(({ label, data }) => (
        <ListItem key={`list-item-${label}`}>
          <LabelData key={`label-data-${label}`} label={labelMapCanHaz(label)} data={data} />
        </ListItem>
      ))}
      <ListItem><Typography variant="h6">Details</Typography></ListItem>
      <ListItem>
        <LabelData label="Last Modified" data={formState?.dateTimeUTC} />
      </ListItem>
      <ListItem>
        <LabelData label="Note" data={formState?.note} />
      </ListItem>
    </List>
  );
}

export default InfoList;
