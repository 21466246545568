import {
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Stack,
  Typography,
} from '@mui/material';

const labelMap = {
  customerName: {
    label: 'Name',
    type: 'field',
  },
  customerNameInternal: {
    label: 'Internal Name',
    type: 'field',
  },
  customerAccessID: {
    label: 'Access ID',
    type: 'field',
  },
  customerTimezone: {
    label: 'Timezone',
    type: 'select',
    options: [
      'America/Los_Angeles',
      'America/Denver',
      'America/Chicago',
      'America/New_York',
      'America/Phoenix',
    ],
  },
  serviceGroupID: {
    label: 'Service Group ID',
    type: 'field',
  },
  customerEventAreaMask: {
    label: 'Event Area Mask',
    type: 'field',
  },
  customerOtherAreaMask: {
    label: 'Other Area Mask',
    type: 'field',
  },
};

function BasicInfoEdit ({ customer, formState, setFormState }) {
  if (!customer) return null;

  function handleChange (e) {
    const newFormState = {
      ...formState,
      [e.target.name]: e.target.value,
    };
    setFormState(newFormState);
  }

  return (
    <Stack>
      <Typography color="yellow">Editing Customers is Dangerous!!!</Typography>
      {Object.keys(customer).map((key) => {
        if (!labelMap[key]) return null;
        if (labelMap[key].type === 'field') {
          return (
            <TextField
              key={labelMap[key].label}
              name={key}
              label={labelMap[key].label}
              margin="normal"
              onChange={handleChange}
              value={formState[key]}
            />
          );
        }
        if (labelMap[key].type === 'select') {
          return (
            <FormControl margin="normal" key={`form-control-${labelMap[key].label}`}>
              <InputLabel>{labelMap[key].label}</InputLabel>
              <Select
                key={labelMap[key].label}
                onChange={handleChange}
                value={formState[key]}
                label={labelMap[key].label}
                name={key}
              >
                {labelMap[key].options.map((option) => (
                  <MenuItem key={`timezone-${option}`} value={option}>{option}</MenuItem>
                ))}
              </Select>
            </FormControl>
          );
        }
        return null;
      })}
    </Stack>
  );
}

export default BasicInfoEdit;
