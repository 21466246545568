import {
  Grid,
  List,
  ListItem,
  Typography,
} from '@mui/material';

function LabelData ({ label, data }) {
  return (
    <Grid container spacing={2}>
      <Grid item xs={4}>
        <Typography variant="overline">{label}</Typography>
      </Grid>
      <Grid item xs={8}>
        <Typography variant="subtitle1">{data}</Typography>
      </Grid>
    </Grid>
  );
}

const labelMap = {
  customerID: 'Customer ID',
  customerName: 'Name',
  customerNameInternal: 'Internal Name',
  customerAccessID: 'Access ID',
  customerTimezone: 'Timezone',
  serviceGroupID: 'Service Group ID',
  customerEventAreaMask: 'Event Area Mask',
  customerOtherAreaMask: 'Other Area Mask',
};

function BasicInfoList ({ customer }) {
  if (!customer) return null;

  return (
    <List>
      {Object.entries(customer).map(([key, data]) => (
        <ListItem key={`list-item-${key}`}>
          <LabelData key={`label-data-${key}`} label={labelMap[key]} data={data} />
        </ListItem>
      ))}
    </List>
  );
}

export default BasicInfoList;
