import { useContext } from 'react';
import MuiAppBar from '@mui/material/AppBar';
import { Typography, Toolbar, Box, LinearProgress, IconButton, Button } from '@mui/material';
import { Menu } from '@mui/icons-material';
import { useAuth0 } from '@auth0/auth0-react';

import { ReactComponent as ChampLogo } from './champ-logo-small.svg';

import { GlobalContext } from '../../context/dataContext';

function AppBar () {
  const { loading, toggleNav } = useContext(GlobalContext);

  const { logout, isAuthenticated } = useAuth0();

  return (
    <MuiAppBar
      position="fixed"
      enableColorOnDark
      sx={{
        backgroundColor: '#977DAB',
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
      open
    >
      <Toolbar>
        <IconButton
          size="large"
          edge="start"
          color="inherit"
          aria-label="menu"
          sx={{ mr: 2 }}
          onClick={toggleNav}
        >
          <Menu />
        </IconButton>
        <ChampLogo />
        <Typography
          component="h6"
          variant="h6"
          color="inherit"
          noWrap
          sx={{ flexGrow: 1, ml: 4 }}
        >
          Admin Dashboard
        </Typography>
        <Box sx={{
          alignSelf: 'right',
          height: '2.5em',
          alignItems: 'flex-end',
          display: 'flex',
          flexDirection: 'row',
        }}
        >
          {isAuthenticated
          && (
          <Button
            color="inherit"
            onClick={() => logout({ returnTo: window.location.origin })}
          >
            Logout
          </Button>
          )}
        </Box>
      </Toolbar>
      {loading && <LinearProgress color="primary" />}
    </MuiAppBar>
  );
}

export default AppBar;
