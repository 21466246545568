import { useState, useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Button, Avatar } from '@mui/material';
import { useNavigate } from 'react-router-dom';

function stringToColor (string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

function colorToSx (string) {
  return { bgcolor: stringToColor(string) };
}

function Image (props) {
  const { row: { customerName } } = props;
  const splitBySpace = customerName.split(' ');

  const firstInital = splitBySpace[0].charAt(0).toUpperCase();
  const secondInitial = splitBySpace[1]?.charAt(0).toUpperCase();
  const initials = `${firstInital}${secondInitial || ''}`;

  return (
    <Avatar alt={customerName} sx={colorToSx(customerName)}>{initials}</Avatar>
  );
}

function DetailButton (props) {
  const navigate = useNavigate();

  const { row: { customerAccessID } } = props;

  function handleClick (e) {
    e.preventDefault();
    navigate(`./${customerAccessID}`);
  }
  return (
    <Button onClick={handleClick}>Detail</Button>
  );
}

const columns = [
  { field: 'image', headerName: '', width: 80, renderCell: Image, hideable: false, filterable: false, sortable: false },
  { field: 'customerName', headerName: 'Name', flex: 1, hideable: false },
  { field: 'customerNameInternal', headerName: 'Internal Name', flex: 1, hideable: false },
  { field: 'customerAccessID', headerName: 'Access ID', flex: 1, hideable: false },
  { field: 'customerTimezone', headerName: 'Timezone', flex: 1, hideable: false },
  { field: 'detail', headerName: '', width: 150, renderCell: DetailButton, filterable: false, sortable: false, hideable: false },
];

function CustomerTable ({ customers, loading = false }) {
  const [rows, setRows] = useState([]);

  useEffect(() => {
    if (customers) {
      const newRows = customers.map((customer) => ({
        id: customer.customerID,
        customerName: customer.customerName,
        customerNameInternal: customer.customerNameInternal,
        customerAccessID: customer.customerAccessID,
        customerTimezone: customer.customerTimezone,
      }));
      setRows(newRows);
    }
  }, [customers]);

  return (
    <div style={{ height: '70vh', width: '100%' }}>
      <DataGrid
        rows={rows}
        columns={columns}
        sx={{ border: 'none' }}
        loading={loading}
        autoPageSize
      />
    </div>
  );
}

export default CustomerTable;
