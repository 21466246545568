import { useState, useEffect } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';

function EditAccessFilterTable ({ boards, roleOptions, handleChange, accessFilter }) {
  const [formState, setFormState] = useState(null);

  useEffect(() => {
    if (boards && !accessFilter) {
      const newFormState = {};
      boards.forEach(({ customerBoardID }) => { newFormState[`board-${customerBoardID}`] = ''; });

      setFormState(newFormState);
    }
    if (boards && accessFilter) {
      const newFormState = {};
      boards.forEach(({ customerBoardID }) => {
        const filter = accessFilter.board.find((item) => item.id === customerBoardID);
        newFormState[`board-${customerBoardID}`] = filter.role;
      });
      setFormState(newFormState);
    }
  }, [accessFilter, boards]);

  if (!boards || !roleOptions || !formState) return null;

  function handleLocalChange (e) {
    const { name, value } = e.target;
    const newFormState = {
      ...formState,
      [name]: value,
    };
    setFormState(newFormState);
    handleChange({
      target: {
        value,
        name,
      },
    });
  }

  return (
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell padding="normal">Board</TableCell>
            <TableCell padding="normal" sx={{ width: '50%' }}>Role</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {boards?.map(({ boardName, customerBoardID }) => (
            <TableRow key={boardName}>
              <TableCell>{boardName}</TableCell>
              <TableCell>
                <FormControl margin="normal" fullWidth size="small">
                  <InputLabel>Role</InputLabel>
                  <Select
                    onChange={handleLocalChange}
                    value={formState[`board-${customerBoardID}`]}
                    label="Role"
                    name={`board-${customerBoardID}`}
                    variant="standard"
                  >
                    {roleOptions?.map((option) => (
                      <MenuItem
                        key={`role-${option}`}
                        value={option}
                      >
                        {option}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default EditAccessFilterTable;
