import { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Skeleton,
  Typography,
} from '@mui/material';
import { DangerousOutlined, MoreVertOutlined } from '@mui/icons-material';
import { toast } from 'react-toastify';

import useApi from '../../hooks/useApi';
import useCallApi from '../../hooks/useCallApi';

import AccessFilterTable from './AccessFiltersTable';
import EditAccessFilterTable from './EditAccessFilterTable';

const ADMIN_SERVER_URL = process.env.REACT_APP_ADMIN_SERVER_URL;

function AccessFiltersTab ({ credential, clearCache }) {
  const [editOpen, setEditOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [formState, setFormState] = useState({ ...credential?.accessFilter?.board });
  const [defaultRoleOptions, setDefaultRoleOptions] = useState([]);

  const [permissions, permissionsLoading] = useApi({
    url: `${ADMIN_SERVER_URL}/v1/permission/byCustomerAccessID/${credential?.champ?.customerAccessID}`,
    method: 'get',
    errorMessage: `Bob Sagat! Can't get permissions for ${credential?.champ?.customerAccessID}`,
  });

  useEffect(() => {
    if (permissions) {
      const roleOptions = permissions.map((permission) => permission.who.role);
      const removedDuplicates = [...new Set(roleOptions)];
      setDefaultRoleOptions(removedDuplicates);
    }
  }, [permissions]);

  const [boards, boardsLoading] = useApi({
    url: `${ADMIN_SERVER_URL}/v1/board/byCustomerAccessID/${credential?.champ?.customerAccessID}`,
    method: 'get',
    errorMessage: `Peanuts! Can't get boards for ${credential?.champ?.customerAccessID}`,
  });

  const callApiNoLogin = useCallApi({
    url: `${ADMIN_SERVER_URL}/v1/credential/noLogin`,
    method: 'put',
  });
  const callApiWithLogin = useCallApi({
    url: `${ADMIN_SERVER_URL}/v1/credential`,
    method: 'put',
  });

  const open = Boolean(anchorEl);

  if (!credential) return null;

  async function handleEditSave () {
    const updatedBoards = Object.entries(formState)
      .map(([board, role]) => ({ id: parseInt(board.replace('board-', ''), 10), role }));

    const { board } = credential.champ.accessFilter;

    const updatedBoardRoles = board.map(({ id, role }) => {
      if (updatedBoards.some((boardRole) => id === boardRole.id)) {
        const newRole = updatedBoards.filter((boardRole) => boardRole.id === id)[0];
        return newRole;
      }
      return { id, role };
    });

    const updatedCredential = {
      ...credential.champ,
      accessFilter: {
        board: [
          ...updatedBoardRoles,
        ],
      },
    };
    console.log({ updatedCredential });
    if (credential?.externalIDs?.auth0) {
      await toast.promise(
        callApiWithLogin({ body: updatedCredential }),
        {
          pending: 'Updating Credential',
          success: 'Credential saved! You may go about your day.',
          error: 'oops save no work',
        },
      );
    } else {
      await toast.promise(
        callApiNoLogin({ body: updatedCredential }),
        {
          pending: 'Updating Credential',
          success: 'Credential saved! You may go about your day.',
          error: 'We have problems. Credential not saved. Try again?',
        },
      );
    }
    clearCache();
  }

  function handleEditOpen () {
    setEditOpen(true);
    setAnchorEl(null);
  }

  function handleEditClose () {
    setEditOpen(false);
  }

  function handleMenuOpen (e) {
    setAnchorEl(e.currentTarget);
  }

  function handleMenuClose () {
    setAnchorEl(null);
  }

  function handleChange (e) {
    const { name, value } = e.target;

    const newFormState = {
      ...formState,
      [name]: value,
    };
    setFormState(newFormState);
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1, m: 2, alignSelf: 'center' }}>
      <Paper elevation={3} sx={{ p: 3, minWidth: 700 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'row', flexGrow: 1 }}>
            <DangerousOutlined color="secondary" sx={{ alignSelf: 'center', mr: 4, width: 30, height: 30 }} />
            <Typography variant="h5" sx={{ alignSelf: 'center' }}>Access Filters</Typography>
            {editOpen && (
              <Box sx={{ alignSelf: 'center' }}>
                <Button
                  color="success"
                  variant="outlined"
                  size="small"
                  sx={{ ml: 2 }}
                  onClick={handleEditSave}
                >
                  Save
                </Button>
                <Button
                  color="error"
                  variant="outlined"
                  size="small"
                  sx={{ ml: 2 }}
                  onClick={handleEditClose}
                >
                  Cancel
                </Button>
              </Box>
            )}
            <IconButton size="large" sx={{ marginLeft: 'auto' }} onClick={handleMenuOpen}>
              <MoreVertOutlined />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={open}
              onClose={handleMenuClose}
              sx={{ ml: '-70px' }}
            >
              <MenuItem
                sx={{ minWidth: 200 }}
                onClick={handleEditOpen}
              >
                Edit Access Filters
              </MenuItem>
            </Menu>
          </Grid>
          <Grid item xs={12}>
            {!editOpen
              && (boardsLoading
                ? <Skeleton height={400} />
                : (
                  <AccessFilterTable
                    boards={boards}
                    accessFilter={credential.champ.accessFilter}
                  />
                ))}
            {editOpen
            && (permissionsLoading
              ? <Skeleton variant="rectangle" height={400} />
              : (
                <EditAccessFilterTable
                  boards={boards}
                  roleOptions={defaultRoleOptions}
                  handleChange={handleChange}
                  accessFilter={credential.champ.accessFilter}
                />
              ))}
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
}

export default AccessFiltersTab;
