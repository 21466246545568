import { useState, useEffect, useContext } from 'react';
import { toast } from 'react-toastify';
import {
  Box,
  Button,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Typography,
} from '@mui/material';
import { DangerousOutlined, MoreVertOutlined } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

import { GlobalContext } from '../../../context/dataContext';

import useCallApi from '../../hooks/useCallApi';
import useApi from '../../hooks/useApi';
import InfoList from './InfoList';
import InfoEdit from './InfoEdit';

const ADMIN_SERVER_URL = process.env.REACT_APP_ADMIN_SERVER_URL;

function PermissionInfo ({ permission, customer }) {
  const { setLoading } = useContext(GlobalContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const [editOpen, setEditOpen] = useState(false);
  const [formState, setFormState] = useState(null);
  const [disableLoad, setDisableLoad] = useState(true);
  const navigate = useNavigate();

  const putPermission = useCallApi({
    url: `${ADMIN_SERVER_URL}/v1/permission`,
    method: 'put',
  });

  const deletePermission = useCallApi({
    url: `${ADMIN_SERVER_URL}/v1/permission/${customer?.customerAccessID}/${permission?.who.role}/${permission?.what.typeName}`,
    method: 'delete',
  });

  const [permissionOptions, loading] = useApi({
    url: `${ADMIN_SERVER_URL}/v1/permission/optionsByType/${permission?.what.typeName}`,
    errorMessage: 'Shoot. I can\'t get them customers.',
    method: 'get',
    disable: disableLoad,
  });

  useEffect(() => {
    setLoading(loading);
  }, [loading, setLoading]);

  useEffect(() => {
    if (permission) {
      setDisableLoad(false);
    }
  }, [permission]);

  useEffect(() => {
    if (permissionOptions && permission) {
      const parsedCanHaz = permissionOptions.map((option) => {
        if (permission.canHaz.some((canHaz) => canHaz === option)) return { label: option, data: 'Yes' };
        return { label: option, data: 'No' };
      });
      const newFormState = {
        ...permission,
        canHaz: parsedCanHaz,
      };

      setFormState(newFormState);
    }
  }, [permission, permissionOptions]);

  if (!permission) return null;
  const open = Boolean(anchorEl);

  function handleMenuOpen (e) {
    setAnchorEl(e.currentTarget);
  }

  function handleMenuClose () {
    setAnchorEl(null);
  }

  function handleEditInfo () {
    setAnchorEl(null);
    setEditOpen(true);
  }

  function handleEditClose () {
    setEditOpen(false);
    const parsedCanHaz = permissionOptions.map((option) => {
      if (permission.canHaz.some((canHaz) => canHaz === option)) return { label: option, data: 'Yes' };
      return { label: option, data: 'No' };
    });
    const newFormState = {
      ...permission,
      canHaz: parsedCanHaz,
    };

    setFormState(newFormState);
  }

  async function handleEditSave () {
    const parsedCanHaz = formState.canHaz
      .map(({ label, data }) => (data === 'Yes' ? label : null))
      .filter((item) => item);

    const updatedPermission = {
      ...formState,
      canHaz: parsedCanHaz,
    };

    if (!updatedPermission.filter) delete updatedPermission.filter;

    console.log({ updatedPermission });

    const result = await toast.promise(
      putPermission({ body: updatedPermission }),
      {
        pending: 'Updating permission y\'all!',
        success: 'Yeeehaaaw. That\'s a sucessful update folks',
        error: 'Shucks... that didn\'t work at all.',
      },
    );
    navigate(`../permissions/${result}`);
  }

  async function handleDeletePermission () {
    await toast.promise(
      deletePermission({ body: null }),
      {
        pending: 'Deletion in progress. I hope this is what you wanted!',
        error: 'Well if you didn\'t want to delete that thing. You\'re in luck. I failed.',
        success: 'That permission is deleted! Bye y\'all',
      },
    );
    navigate(`../permissions`);
  }

  return (
    <Box component={Paper} elevation={3} sx={{ display: 'flex', flexDirection: 'column', width: 700, m: 2, alignSelf: 'center', justifySelf: 'center' }}>
      <Paper elevation={3} sx={{ p: 3, minWidth: 700 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'row', flexGrow: 1 }}>
            <DangerousOutlined color="secondary" sx={{ alignSelf: 'center', mr: 4, width: 30, height: 30 }} />
            <Typography variant="h5" sx={{ alignSelf: 'center' }}>Permission</Typography>
            {editOpen && (
            <Box sx={{ alignSelf: 'center' }}>
              <Button
                color="success"
                variant="outlined"
                size="small"
                sx={{ ml: 2 }}
                onClick={handleEditSave}
              >
                Save
              </Button>
              <Button
                color="error"
                variant="outlined"
                size="small"
                sx={{ ml: 2 }}
                onClick={handleEditClose}
              >
                Cancel
              </Button>
            </Box>
            )}
            <IconButton size="large" sx={{ marginLeft: 'auto' }} onClick={handleMenuOpen}>
              <MoreVertOutlined />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={open}
              onClose={handleMenuClose}
              sx={{ ml: '-70px' }}
            >
              <MenuItem
                onClick={handleEditInfo}
                sx={{ minWidth: 200 }}
              >
                Edit Permission
              </MenuItem>
              <MenuItem
                sx={{ minWidth: 200 }}
                onClick={handleDeletePermission}
              >
                <Typography color="error">Delete Permission</Typography>
              </MenuItem>
            </Menu>
          </Grid>
          <Grid item xs={12}>
            {editOpen
              ? (
                <InfoEdit
                  formState={formState}
                  setFormState={setFormState}
                  customer={customer}
                />
              )
              : <InfoList formState={formState} customer={customer} />}
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
}

export default PermissionInfo;
