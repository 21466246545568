import { useState } from 'react';
import { Box, Grid, Tabs, Tab, Paper } from '@mui/material';

import InfoTab from './InfoTab';
import AccessFiltersTab from './AccessFiltersTab';

function TabPanel (props) {
  const { children, value, index } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
    >
      {value === index && (
        <Box sx={{ p: 3, display: 'flex', flexDirection: 'column' }}>
          {children}
        </Box>
      )}
    </div>
  );
}

function CredentialDetailTabs ({ credential, clearCache }) {
  const [value, setValue] = useState(0);

  function handleTabChange (e, newValue) {
    setValue(newValue);
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1, m: 2 }}>
      <Grid container spacing={2} sx={{ flexGrow: 1 }}>
        <Grid item xs={12}>
          <Paper elevation={2} sx={{ p: 2 }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs
                value={value}
                onChange={handleTabChange}
                variant="fullWidth"
              >
                <Tab label="Info" />
                <Tab label="Access Filters" />
              </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
              <InfoTab credential={credential} clearCache={clearCache} />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <AccessFiltersTab credential={credential} clearCache={clearCache} />
            </TabPanel>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
}

export default CredentialDetailTabs;
