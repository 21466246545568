import { useState } from 'react';
import { toast } from 'react-toastify';
import {
  TextField,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
} from '@mui/material';

import useCallApi from '../hooks/useCallApi';

const ADMIN_SERVER_URL = process.env.REACT_APP_ADMIN_SERVER_URL;

const fields = [
  {
    name: 'customerName',
    label: 'Customer Name',
  },
  {
    name: 'customerNameInternal',
    label: 'Internal Name',
  },
  {
    name: 'customerAccessID',
    label: 'Access ID',
  },
];

const timezoneOptions = [
  'America/Los_Angeles',
  'America/Denver',
  'America/Chicago',
  'America/New_York',
  'America/Phoenix',
];

function NewCustomerDialog ({ isOpen, setOpen, callbackCancel, callbackConfirm }) {
  const [formState, setFormState] = useState({
    customerName: '',
    customerNameInternal: '',
    customerAccessID: '',
    customerTimezone: '',
  });

  const callApi = useCallApi({
    url: `${ADMIN_SERVER_URL}/v1/customer`,
    method: 'post',
  });

  function handleChange (e) {
    const newFormState = {
      ...formState,
      [e.target.name]: e.target.value,
    };
    setFormState(newFormState);
  }

  async function handleConfirm (e) {
    e.preventDefault();
    setOpen(false);
    await toast.promise(
      callApi({ body: formState }),
      {
        pending: 'Creating new customer. This may take a minute.',
        success: 'Yippee. Customer is created!',
        error: 'Awe hell. Customer creation not successful. Try again? Call John?',
      },
    );
    callbackConfirm();
  }

  return (
    <Dialog
      open={isOpen}
      onClose={() => setOpen(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <Box sx={{ p: 3 }}>
        <DialogTitle id="alert-dialog-title" sx={{ width: '500px' }}>
          Create New Customer
        </DialogTitle>
        <DialogContent>
          <Stack sx={{ flexGrow: 1 }}>
            {fields.map((field) => (
              <TextField
                key={field.name}
                name={field.name}
                label={field.label}
                margin="normal"
                onChange={handleChange}
                value={formState[field.name]}
              />
            ))}
            <FormControl margin="normal">
              <InputLabel>Customer Timezone</InputLabel>
              <Select
                onChange={handleChange}
                value={formState.customerTimezone}
                label="Customer Timezone"
                name="customerTimezone"
              >
                {timezoneOptions.map((option) => (
                  <MenuItem key={`timezone-${option}`} value={option}>{option}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={callbackCancel}>Cancel</Button>
          <Button onClick={handleConfirm} autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Box>

    </Dialog>
  );
}

export default NewCustomerDialog;
