/* eslint-disable no-underscore-dangle */
import { useState, useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Button, Chip } from '@mui/material';
import { useNavigate } from 'react-router-dom';

function DetailButton (props) {
  const navigate = useNavigate();

  const { row: { id } } = props;

  function handleClick (e) {
    e.preventDefault();
    console.log('detail click', id);
    navigate(`../permissions/${id}`);
  }
  return (
    <Button onClick={handleClick}>Detail</Button>
  );
}

function CanHaz (props) {
  const { row: { canHaz } } = props;

  const chips = canHaz.map((item) => (<Chip label={item} key={item} variant="outlined" sx={{ mr: 1 }} />));

  return chips;
}

const columns = [
  { field: 'role', headerName: 'Role', flex: 1, hideable: false },
  { field: 'type', headerName: 'Type', flex: 2, hideable: false },
  { field: 'canHaz', headerName: 'Can Haz?', renderCell: CanHaz, flex: 7, hideable: false },
  { field: 'detail', headerName: '', width: 100, renderCell: DetailButton, filterable: false, sortable: false, hideable: false },
];

function PermissionTable ({ permissions, height = '70vh', loading = false }) {
  const [rows, setRows] = useState([]);

  useEffect(() => {
    if (permissions) {
      const newRows = permissions.map((permission) => ({
        id: permission._id,
        role: permission.who.role,
        type: permission.what.typeName,
        canHaz: permission.canHaz,
      }));
      setRows(newRows);
    }
  }, [permissions]);

  return (
    <div style={{ height, width: '100%' }}>
      <DataGrid
        rows={rows}
        columns={columns}
        sx={{ border: 'none' }}
        loading={loading}
        autoPageSize
      />
    </div>
  );
}

export default PermissionTable;
