import { useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import {
  Paper,
  Box,
} from '@mui/material';

import { GlobalContext } from '../../../context/dataContext';

import useApi from '../../hooks/useApi';

import CustomerDetailSkeleton from './CustomerDetailSkeleton';
import CustomerDetailHeader from './CustomerDetailHeader';
import CustomerDetailTabs from './CustomerDetailTabs';

const ADMIN_SERVER_URL = process.env.REACT_APP_ADMIN_SERVER_URL;

function CustomerDetail () {
  const { customerAccessID } = useParams();
  const { setLoading } = useContext(GlobalContext);

  const [customer, loading, clearCache] = useApi({
    url: `${ADMIN_SERVER_URL}/v1/customer/byAccessID/${customerAccessID}`,
    method: 'get',
    errorMessage: 'Unable to get individual customer. I blame Cox',
  });

  useEffect(() => {
    setLoading(loading);
  }, [loading, setLoading]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        m: 2,
        height: '100%',
      }}
    >
      <Paper sx={{ flexGrow: 1, p: 4 }}>
        {loading
          ? <CustomerDetailSkeleton />
          : (
            <>
              <CustomerDetailHeader customer={customer} />
              <CustomerDetailTabs customer={customer} clearCache={clearCache} />
            </>
          )}
      </Paper>
    </Box>
  );
}

export default CustomerDetail;
