import { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { GlobalContext } from '../../../context/dataContext';

import PermissionTable from '../../permission/PermissionTable';

import useApi from '../../hooks/useApi';

const ADMIN_SERVER_URL = process.env.REACT_APP_ADMIN_SERVER_URL;

function PermissionTab () {
  const { customerAccessID } = useParams();
  const { setLoading } = useContext(GlobalContext);

  const [permissions, loading] = useApi({
    url: `${ADMIN_SERVER_URL}/v1/permission/byCustomerAccessID/${customerAccessID}`,
    method: 'get',
    errorMessage: `Bob Sagat! Can't get permissions for ${customerAccessID}`,
  });

  useEffect(() => {
    setLoading(loading);
  }, [loading, setLoading]);

  return (
    <PermissionTable permissions={permissions} height="50vh" loading={loading} />
  );
}

export default PermissionTab;
