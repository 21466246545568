/* eslint-disable no-underscore-dangle */
import {
  Box,
  Grid,
} from '@mui/material';

import EmailResponseTimeGraph from './EmailResponeTimeGraph';

function Home () {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1, m: 2 }}>
      <Grid container spacing={2} sx={{ flexGrow: 1 }}>
        <Grid item lg={6}>
          <EmailResponseTimeGraph />
        </Grid>
      </Grid>
    </Box>
  );
}

export default Home;
