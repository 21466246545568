import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import {
  TextField,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  Typography,
  Skeleton,
} from '@mui/material';

import useApi from '../../hooks/useApi';
import RoleSelect from '../permission-detail/RoleSelect';
import useCallApi from '../../hooks/useCallApi';

const ADMIN_SERVER_URL = process.env.REACT_APP_ADMIN_SERVER_URL;

const formDefault = {
  customerID: '',
  role: '',
  typeName: '',
  canHaz: [],
  note: 'Created with the Admin Dashboard',
};

const canHazSelectOptions = ['Yes', 'No'];

function labelMapCanHaz (rawLabel) {
  if (rawLabel.includes('_')) {
    const label = rawLabel
      .split('_')
      // uppercase https://stackoverflow.com/questions/1026069/how-do-i-make-the-first-letter-of-a-string-uppercase-in-javascript
      .map((item) => item.charAt(0).toUpperCase() + item.slice(1))
      .join(' ');
    return label;
  }
  const label = rawLabel.charAt(0).toUpperCase() + rawLabel.slice(1);
  return label;
}

function NewPermissionDialog ({ isOpen, setOpen, callbackCancel, callbackConfirm, customers }) {
  const [formState, setFormState] = useState(formDefault);
  const [customerOptions, setCustomerOptions] = useState(null);
  const [selectedCustomerAccessID, setSelectedCustomerAccessID] = useState(null);
  const [disableLoad, setDisableLoad] = useState(true);

  useEffect(() => {
    if (customers) {
      const newCustomerOptions = customers.map(({ customerName, customerID }) => (
        { customerName, customerID }
      ));
      setCustomerOptions(newCustomerOptions);
    }
  }, [customers]);

  useEffect(() => {
    if (!isOpen) {
      setFormState(formDefault);
      setDisableLoad(true);
    }
  }, [isOpen]);

  useEffect(() => {
    if (formState.customerID !== '') {
      const { customerAccessID } = customers.filter(({ customerID }) => (
        customerID === formState.customerID
      ))[0];
      setSelectedCustomerAccessID(customerAccessID);
    }
  }, [customers, formState.customerID]);

  useEffect(() => {
    if (formState.typeName) {
      setDisableLoad(false);
    }
  }, [formState.typeName]);

  const [whatTypeNameOptions, typeNameOptionsLoading] = useApi({
    url: `${ADMIN_SERVER_URL}/v1/permission/whatTypeNameOptions`,
    errorMessage: 'Shoot. I can\'t get them Type Name options.',
    method: 'get',
  });

  const [canHazOptions, canHazOptionsLoading, clearCanHazCache] = useApi({
    url: `${ADMIN_SERVER_URL}/v1/permission/optionsByType/${formState?.typeName}`,
    errorMessage: 'Shoot. I can\'t get them can haz options.',
    method: 'get',
    disable: disableLoad,
  });

  useEffect(() => {
    if (canHazOptions && formState.canHaz.length === 0) {
      const newCanHaz = canHazOptions.map((option) => ({ label: option, value: 'No' }));
      const newFormState = {
        ...formState,
        canHaz: newCanHaz,
      };
      setFormState(newFormState);
    }
  }, [canHazOptions, formState]);

  function handleChange (e) {
    const { name, value } = e.target;

    if (name.includes('canHaz-')) {
      const newLabel = name.replace('canHaz-', '');
      const canHazReplacementIndex = formState.canHaz.findIndex(({ label }) => label === newLabel);
      const newCanHaz = formState.canHaz.filter(({ label }) => label !== newLabel);
      newCanHaz.splice(canHazReplacementIndex, 0, {
        label: newLabel,
        value,
      });

      const newFormState = {
        ...formState,
        canHaz: newCanHaz,
      };
      setFormState(newFormState);
    } else if (name === 'typeName') {
      const newFormState = {
        ...formState,
        canHaz: [],
        [name]: value,
      };
      clearCanHazCache();
      setFormState(newFormState);
    } else {
      const newFormState = {
        ...formState,
        [name]: value,
      };
      setFormState(newFormState);
    }
  }

  function handleRoleChange (role) {
    const newFormState = {
      ...formState,
      role: role.replace('Add ', ''),
    };
    setFormState(newFormState);
  }

  const postPermission = useCallApi({
    url: `${ADMIN_SERVER_URL}/v1/permission`,
    method: 'post',
  });

  async function handleConfirm () {
    const {
      customerID,
      role,
      typeName,
      canHaz,
      note,
    } = formState;

    const parsedCanHaz = canHaz
      .map(({ label, value }) => (value === 'Yes' ? label : null))
      .filter((item) => item);

    setOpen(false);

    const newPermission = {
      who: {
        customerID,
        role,
      },
      what: { typeName },
      canHaz: parsedCanHaz,
      note,
    };
    await toast.promise(
      postPermission({ body: newPermission }),
      {
        pending: 'Hullo, I am working on that new permission',
        error: 'Balls. Can\'t create that permission for reasons I\'m sure.',
        success: 'Woot woot! I made a new permission aweeee how cute!',
      },
    );
    callbackConfirm();
  }

  return (
    <Dialog
      open={isOpen}
      onClose={() => setOpen(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <Box sx={{ p: 3 }}>
        <DialogTitle id="alert-dialog-title" sx={{ width: '500px' }}>
          Create New Permission
        </DialogTitle>
        <DialogContent>
          <Stack sx={{ flexGrow: 1 }}>
            <Typography variant="h6">Who</Typography>
            <FormControl margin="normal" fullWidth sx={{ mt: 2 }}>
              <InputLabel required>Customer</InputLabel>
              <Select
                onChange={handleChange}
                value={formState.customerID}
                label="Customer"
                name="customerID"
                required
              >
                {customerOptions?.map((option) => (
                  <MenuItem
                    key={`customer-${option.customerName}`}
                    value={option.customerID}
                  >
                    {option.customerName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {selectedCustomerAccessID
              ? (
                <RoleSelect
                  role={formState.role}
                  setRole={handleRoleChange}
                  customerAccessID={selectedCustomerAccessID}
                  variant="outlined"
                />
              )
              : <Skeleton variant="rectangle" height={56} sx={{ mt: 1.5 }} />}
            {formState.role && (
              <>
                <Typography variant="h6" sx={{ mt: 3 }}>What</Typography>
                {
                typeNameOptionsLoading
                  ? <Skeleton variant="rectangular" width="auto" height={56} sx={{ mt: 1.5 }} />
                  : (
                    <FormControl variant="outlined" margin="normal" fullWidth sx={{ mt: 1 }}>
                      <InputLabel>Type</InputLabel>
                      <Select
                        onChange={handleChange}
                        value={whatTypeNameOptions ? formState.typeName : ''}
                        label="Type"
                        name="typeName"
                      >
                        {whatTypeNameOptions?.map((option) => (
                          <MenuItem
                            key={`typeName-${option}`}
                            value={option}
                          >
                            {option}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )
                }
              </>
            )}
            {formState.typeName && (
              <>
                <Typography variant="h6" sx={{ mt: 3 }}>Can Haz?</Typography>
                {canHazOptionsLoading
                  ? <Skeleton variant="rectangular" width="auto" height={128} sx={{ mt: 1.5 }} />
                  : formState.canHaz.map(({ label, value }) => (
                    <FormControl key={`form-control-${label}`} variant="outlined" margin="normal" fullWidth sx={{ mt: 1 }}>
                      <InputLabel key={label}>{labelMapCanHaz(label)}</InputLabel>
                      <Select
                        onChange={handleChange}
                        value={value}
                        label={labelMapCanHaz(label)}
                        name={`canHaz-${label}`}
                        key={`canHaz-${label}`}
                      >
                        {canHazSelectOptions.map((option) => (
                          <MenuItem
                            key={`canHaz-${label}-${option}`}
                            value={option}
                          >
                            {option}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  ))}
                <Typography variant="h6" sx={{ mt: 3 }}>Details</Typography>
                <TextField
                  name="note"
                  label="Note"
                  margin="normal"
                  onChange={handleChange}
                  value={formState.note}
                  multiline
                  variant="outlined"
                />
              </>
            )}
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={callbackCancel}>Cancel</Button>
          <Button onClick={handleConfirm} autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
}

export default NewPermissionDialog;
