import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import {
  Box,
  Button,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Typography,
} from '@mui/material';
import { LocationCityOutlined, MoreVertOutlined } from '@mui/icons-material';

import useCallApi from '../../hooks/useCallApi';

import BasicInfoList from './BasicInfoList';
import BasicInfoEdit from './BasicInfoEdit';

const ADMIN_SERVER_URL = process.env.REACT_APP_ADMIN_SERVER_URL;

function CustomerDetailBasicInfo ({ customer, clearCache }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [editOpen, setEditOpen] = useState(false);
  const callApi = useCallApi({
    url: `${ADMIN_SERVER_URL}/v1/customer`,
    method: 'put',
  });
  const [formState, setFormState] = useState({
    customerName: '',
    customerNameInternal: '',
    customerAccessID: '',
    customerTimezone: '',
    serviceGroupID: '',
    customerEventAreaMask: '',
    customerOtherAreaMask: '',
  });

  useEffect(() => {
    if (customer) {
      setFormState(customer);
    }
  }, [customer]);

  if (!customer) return null;
  const open = Boolean(anchorEl);

  function handleMenuOpen (e) {
    setAnchorEl(e.currentTarget);
  }

  function handleMenuClose () {
    setAnchorEl(null);
  }

  function handleEditInfo () {
    setAnchorEl(null);
    setEditOpen(true);
    toast.warn('Editing customers is dangerous. Call Dan if you\'re unsure.');
  }

  function handleEditClose () {
    setEditOpen(false);
  }

  async function handleEditSave () {
    setEditOpen(false);
    await toast.promise(
      callApi({ body: formState }),
      {
        pending: 'Updating Customer.',
        success: `${formState.customerName} saved!`,
        error: `Welp. ${formState.customerName} wasn't updated.`,
      },
    );
    clearCache();
  }

  return (
    <Box component={Paper} elevation={3} sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1, m: 2, alignSelf: 'center' }}>
      <Paper elevation={3} sx={{ p: 3, minWidth: 700 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'row', flexGrow: 1 }}>
            <LocationCityOutlined color="secondary" sx={{ alignSelf: 'center', mr: 4, width: 30, height: 30 }} />
            <Typography variant="h5" sx={{ alignSelf: 'center' }}>Basic Info</Typography>
            {editOpen && (
              <Box sx={{ alignSelf: 'center' }}>
                <Button
                  color="success"
                  variant="outlined"
                  size="small"
                  sx={{ ml: 2 }}
                  onClick={handleEditSave}
                >
                  Save
                </Button>
                <Button
                  color="error"
                  variant="outlined"
                  size="small"
                  sx={{ ml: 2 }}
                  onClick={handleEditClose}
                >
                  Cancel
                </Button>
              </Box>
            )}
            <IconButton size="large" sx={{ marginLeft: 'auto' }} onClick={handleMenuOpen}>
              <MoreVertOutlined />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={open}
              onClose={handleMenuClose}
              sx={{ ml: '-70px' }}
            >
              <MenuItem
                onClick={handleEditInfo}
                sx={{ minWidth: 200 }}
              >
                Edit Info
              </MenuItem>
            </Menu>
          </Grid>
          <Grid item xs={12}>
            {editOpen
              ? <BasicInfoEdit customer={customer} formState={formState} setFormState={setFormState} />
              : <BasicInfoList customer={customer} />}
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
}

export default CustomerDetailBasicInfo;
