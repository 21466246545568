import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Box, CssBaseline } from '@mui/material';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { Auth0Provider } from '@auth0/auth0-react';
import 'react-toastify/dist/ReactToastify.css';

import { GlobalContextProvider } from '../../context/dataContext';

import AppBar from '../app-bar/AppBar';
import LoginHandler from './LoginHandler';

const AUTH_AUDIENCE = process.env.REACT_APP_AUTH_AUDIENCE || 'com-champds-adminserver-dev';
const AUTH_DOMAIN = process.env.REACT_APP_AUTH_DOMAIN || 'cds-auth-dev.us.auth0.com';
const AUTH_CLIENT_ID = process.env.REACT_APP_AUTH_CLIENT_ID || 'icxkqel2SbJB5YMfGkXwozNWQbxrY3VP';

const theme = createTheme({
  palette: {
    mode: 'dark',
    champPurple: {
      main: '#522773',
      light: '#977DAB',
    },
  },
});

function App () {
  return (
    <Auth0Provider
      domain={AUTH_DOMAIN}
      clientId={AUTH_CLIENT_ID}
      redirectUri={window.location.origin}
      scope="crud:admin openid email"
      audience={AUTH_AUDIENCE}
    >
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <Box sx={{ display: 'flex ' }}>
            <CssBaseline />
            <ToastContainer theme="dark" />
            <GlobalContextProvider>
              <AppBar />
              <LoginHandler />
            </GlobalContextProvider>
          </Box>
        </BrowserRouter>
      </ThemeProvider>
    </Auth0Provider>
  );
}

export default App;
