import { Skeleton, Box, Grid, Stack } from '@mui/material';

function CredentialDetailSkeleton () {
  return (
    <Box>
      <Grid container>
        <Grid item xs={12} sx={{ display: 'flex', mt: 2 }}>
          <Skeleton variant="circular" width={50} height={50} sx={{ alignSelf: 'start' }} />
          <Skeleton variant="circular" width={180} height={180} />
          <Stack sx={{ ml: 4 }}>
            <Skeleton variant="text" width={400} height={80} sx={{ alignSelf: 'center', m: 1 }} />
            <Skeleton variant="text" width={400} height={30} sx={{ alignSelf: 'center', m: 1 }} />
            <Stack direction="row">
              <Skeleton variant="rectangle" width={100} height={30} sx={{ alignSelf: 'center', m: 1, borderRadius: '20px' }} />
              <Skeleton variant="rectangle" width={100} height={30} sx={{ alignSelf: 'center', m: 1, borderRadius: '20px' }} />
              <Skeleton variant="rectangle" width={100} height={30} sx={{ alignSelf: 'center', m: 1, borderRadius: '20px' }} />
            </Stack>
          </Stack>
        </Grid>
        <Grid container sx={{ mt: 2 }} spacing={2}>
          <Grid item xs={6}>
            <Skeleton variant="text" height={70} />
          </Grid>
          <Grid item xs={6}>
            <Skeleton variant="text" height={70} />
          </Grid>
        </Grid>
        <Grid item xs={12} sx={{ mt: 4 }}>
          <Skeleton variant="rectangle" height={500} />
        </Grid>
      </Grid>

    </Box>
  );
}

export default CredentialDetailSkeleton;
