import { useEffect, useState, useRef } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import { toast } from 'react-toastify';

const AUTH_AUDIENCE = process.env.REACT_APP_AUTH_AUDIENCE || 'com-champds-adminserver-dev';

function useApi ({ url, method = 'get', errorMessage, body, disable = false }) {
  const cache = useRef({});
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const { getAccessTokenSilently, user } = useAuth0();

  function clearCache () {
    console.log('clearing hook cache');
    cache.current = {};
    setData(null);
    setLoading(true);
  }

  useEffect(() => {
    if (!url) return;
    if (disable) return;

    async function callApi () {
      try {
        setLoading(true);

        // this prevents the getAccessTokenSilently
        // calling the useEffect more than once
        // otherwise everytime you call getAccessToken
        // it will update hence calling the useEffect
        if (!cache.current.token) {
          const token = await getAccessTokenSilently({
            audience: AUTH_AUDIENCE,
            scope: 'crud:admin openid',
          });

          cache.current.token = token;
        }

        if (cache.current[url]) {
          const cacheData = cache.current[url];
          setData(cacheData);
          setLoading(false);
        } else if (!cache.current.loadingFromServer) {
          // this prevents the async function being called
          // multiple times while it's awaiting data
          cache.current.loadingFromServer = true;
          const config = {
            method,
            headers: {
              Authorization: `Bearer ${cache.current.token}`,
              'cds-remoteid': user.email,
            },
            data: body,
          };
          const result = await axios(url, config);
          cache.current[url] = result.data;

          setData(result.data);
          setLoading(false);
          cache.current.loadingFromServer = false;
        } else {
          return;
        }
      } catch (e) {
        toast.error(errorMessage || 'Cannot retrieve data.');
        setLoading(false);
      }
    }

    callApi();
  }, [body, errorMessage, getAccessTokenSilently, loading, method, url, user, disable]);

  return [data, loading, clearCache];
}

export default useApi;
