import { Skeleton, Box, Grid } from '@mui/material';

function CustomerDetailSkeleton () {
  return (
    <Box>
      <Grid container>
        <Grid item xs={12} sx={{ display: 'flex', mt: 2 }}>
          <Skeleton variant="circular" width={50} height={50} sx={{ alignSelf: 'start' }} />
          <Skeleton variant="circular" width={180} height={180} />
          <Skeleton variant="text" width={400} height={80} sx={{ alignSelf: 'center', m: 4 }} />
        </Grid>
        <Grid container sx={{ mt: 2 }} spacing={2}>
          <Grid item xs={4}>
            <Skeleton variant="text" height={70} />
          </Grid>
          <Grid item xs={4}>
            <Skeleton variant="text" height={70} />
          </Grid>
          <Grid item xs={4}>
            <Skeleton variant="text" height={70} />
          </Grid>
        </Grid>
        <Grid item xs={12} sx={{ mt: 4 }}>
          <Skeleton variant="rectangle" height={500} />
        </Grid>
      </Grid>

    </Box>
  );
}

export default CustomerDetailSkeleton;
