import { useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import {
  Paper,
  Box,
} from '@mui/material';

import { GlobalContext } from '../../../context/dataContext';

import useApi from '../../hooks/useApi';

import CredentialDetailSkeleton from './CredentialDetailSkeleton';
import CredentialDetailHeader from './CredentialDetailHeader';
import CredentialDetailTabs from './CredentialDetailTabs';

const ADMIN_SERVER_URL = process.env.REACT_APP_ADMIN_SERVER_URL;

function CredentialDetail () {
  const { email } = useParams();
  const { setLoading } = useContext(GlobalContext);

  const [credential, loading, clearCache] = useApi({
    url: `${ADMIN_SERVER_URL}/v1/credential/byEmail/${email}`,
    method: 'get',
    errorMessage: `Welp. We dun got some problems. can't find ${email}`,
  });

  useEffect(() => {
    setLoading(loading);
  }, [loading, setLoading]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        m: 2,
        height: '100%',
      }}
    >
      <Paper sx={{ flexGrow: 1, p: 4 }}>
        {loading
          ? <CredentialDetailSkeleton />
          : (
            <>
              <CredentialDetailHeader credential={credential} />
              <CredentialDetailTabs credential={credential} clearCache={clearCache} />
            </>
          )}
      </Paper>
    </Box>
  );
}

export default CredentialDetail;
