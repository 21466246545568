/* eslint-disable no-underscore-dangle */
import { useEffect, useState, useContext } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import {
  Typography,
  Paper,
  Skeleton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
} from '@mui/material';

import { GlobalContext } from '../../context/dataContext';

import useApi from '../hooks/useApi';

const ADMIN_SERVER_URL = process.env.REACT_APP_ADMIN_SERVER_URL;

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
);

const chartOptions = {
  responsive: true,
  color: '#fff',
  borderColor: '#fff',
  grid: { color: '#fff' },
  scales: {
    x: {
      ticks: { color: '#fff' },
      grid: { color: '#3f3f3f' },
    },
    y: {
      title: {
        display: true,
        text: 'Hours',
        color: '#fff',
      },
      ticks: { color: '#fff' },
      grid: { color: '#3f3f3f' },
    },
  },
  plugins: {
    title: {
      text: 'Email Response Time',
      display: false,
    },
    legend: {
      position: 'top',
      color: '#fff',
    },
  },
};

function generateSelectOptions (howMany) {
  const newSelectOptions = [];
  // eslint-disable-next-line no-plusplus
  for (let i = 1; howMany >= i; i++) {
    newSelectOptions.push(i);
  }
  return newSelectOptions;
}

const selectOptions = generateSelectOptions(31);

const colors = [
  '#522773',
  '#FFC101',
  '#8AD000',
  '#4CC2E5',
];

function EmailResponseTimeGraph () {
  const [data, setData] = useState(null);
  const [days, setDays] = useState(14);
  const { setLoading } = useContext(GlobalContext);

  const [dailyResponseTimes, loading] = useApi({
    url: `${ADMIN_SERVER_URL}/v1/emailResponseTimes/dailyResponseTimes?days=${days}`,
    errorMessage: 'Chestnuts, no response times are coming.',
    method: 'get',
  });

  useEffect(() => {
    setLoading(loading);
  }, [loading, setLoading]);

  useEffect(() => {
    if (dailyResponseTimes) {
      const labels = dailyResponseTimes[0].responseTimes.map((item) => item.day);
      const datasets = dailyResponseTimes.map((item, i) => ({
        label: item._id,
        data: item.responseTimes.map((dataByActor) => dataByActor.timeSec / 60 / 60),
        backgroundColor: colors[i % 4],
      }));

      const newData = {
        labels,
        datasets,
      };
      setData(newData);
    }
  }, [dailyResponseTimes]);
  if (!dailyResponseTimes || !data || data.length < 1) return null;

  function handleSelect (e) {
    setDays(e.target.value);
  }

  return (
    <Paper sx={{ padding: 4 }}>
      {loading
        ? <Skeleton height={600} />
        : (
          <>
            <Box sx={{ display: 'flex', mb: 2 }}>
              <Typography variant="h6" sx={{ mt: 2 }}>Response Times</Typography>
              <FormControl margin="normal" sx={{ mt: 1, ml: 'auto', width: 100 }}>
                <InputLabel>Days</InputLabel>
                <Select
                  onChange={handleSelect}
                  value={days}
                  label="Days"
                  name="Days"
                >
                  {selectOptions?.map((option) => (
                    <MenuItem
                      key={option}
                      value={option}
                    >
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <Bar
              options={chartOptions}
              data={data}
            />
          </>
        )}
    </Paper>
  );
}

export default EmailResponseTimeGraph;
