import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  Box,
  Button,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Typography,
} from '@mui/material';
import { PersonPinCircleOutlined, MoreVertOutlined } from '@mui/icons-material';

import useCallApi from '../../hooks/useCallApi';

import BasicInfoList from './BasicInfoList';
import BasicInfoEdit from './BasicInfoEdit';
import AlertDialog from '../../dialogs/AlertDialog';

const ADMIN_SERVER_URL = process.env.REACT_APP_ADMIN_SERVER_URL;

const defaultFormState = {
  email: '',
  title: '',
  firstName: '',
  lastName: '',
  pronouns: '',
  phone: '',
  defaultRole: '',
  note: '',
};

function InfoTab ({ credential, clearCache }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [editOpen, setEditOpen] = useState(false);
  const [deleteAlertOpen, setDeleteAlertOpen] = useState(false);
  const [formState, setFormState] = useState(defaultFormState);
  const navigate = useNavigate();

  useEffect(() => {
    if (credential) {
      const newFormState = {};
      Object.keys(defaultFormState).forEach((key) => {
        newFormState[key] = credential.champ[key] || '';
      });
      setFormState(newFormState);
    }
  }, [credential]);

  const callApiNoLogin = useCallApi({
    url: `${ADMIN_SERVER_URL}/v1/credential/noLogin`,
    method: 'put',
  });
  const callApiWithLogin = useCallApi({
    url: `${ADMIN_SERVER_URL}/v1/credential`,
    method: 'put',
  });
  const deleteCredential = useCallApi({
    url: `${ADMIN_SERVER_URL}/v1/credential/byEmail/${encodeURIComponent(credential?.champ.email)}`,
    method: 'delete',
  });
  const removeLogin = useCallApi({
    url: `${ADMIN_SERVER_URL}/v1/credential/removeLogin`,
    method: 'patch',
  });
  const addLogin = useCallApi({
    url: `${ADMIN_SERVER_URL}/v1/credential/addLogin`,
    method: 'patch',
  });
  const resetPassword = useCallApi({
    url: `${ADMIN_SERVER_URL}/v1/credential/resetPassword`,
    method: 'post',
  });

  if (!credential) return null;
  const open = Boolean(anchorEl);

  function handleMenuOpen (e) {
    setAnchorEl(e.currentTarget);
  }

  function handleMenuClose () {
    setAnchorEl(null);
  }

  function handleEditOpen () {
    setEditOpen(true);
  }

  function handleEditClose () {
    setEditOpen(false);
  }

  async function handleEditSave () {
    setEditOpen(false);
    const update = {
      ...credential.champ,
      ...formState,
    };
    console.log({ update });
    if (credential?.externalIDs?.auth0) {
      await toast.promise(
        callApiWithLogin({ body: update }),
        {
          pending: 'Updating Credential',
          success: 'Credential saved! You may go about your day.',
          error: 'We have problems. Credential not saved. Try again?',
        },
      );
    } else {
      await toast.promise(
        callApiNoLogin({ body: update }),
        {
          pending: 'Updating Credential',
          success: 'Credential saved! You may go about your day.',
          error: 'We have problems. Credential not saved. Try again?',
        },
      );
    }
    clearCache();
  }

  async function handleAddLogin () {
    await toast.promise(
      addLogin({ body: credential.champ }),
      {
        pending: 'Adding login',
        success: `Login added. We have sent a verification and password reset to ${credential.champ.email}`,
        error: 'Well dang. Unable to add login...',
      },
    );
    clearCache();
  }

  async function handleRemoveLogin () {
    await toast.promise(
      removeLogin({ body: credential.champ }),
      {
        pending: 'Removing Login',
        success: 'Zip zap zoop no more login',
        error: 'Ooops we are unable to do that',
      },
    );
    clearCache();
  }

  async function handleResetPassword () {
    await toast.promise(
      resetPassword({ body: { email: credential.champ.email } }),
      {
        pending: 'Roger. We\'ve got a password reset in progress',
        success: 'Affirmative. That reset is complete',
        error: 'Server to client. We can\'t complete that password request you asked for.',
      },
    );
  }

  async function handleDeleteLogin () {
    setDeleteAlertOpen(true);
  }

  async function deleteLogin () {
    setDeleteAlertOpen(false);
    try {
      await toast.promise(
        deleteCredential({ body: null }),
        {
          pending: 'Deleting Credential',
          succuss: 'Bye. Bye. Person.',
          error: 'Oh dear. Not deleted',
        },
      );
      navigate('../credentials');
    } catch (e) {
      console.error(e);
    }
  }

  return (
    <>
      <AlertDialog
        isOpen={deleteAlertOpen}
        setOpen={setDeleteAlertOpen}
        callbackConfirm={deleteLogin}
        callbackCancel={() => setDeleteAlertOpen(false)}
        title="About to Delete Credential"
        message="A credential can be brought back from the dead but it's not easy. Are you sure you want to do this?"
      />
      <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1, m: 2, alignSelf: 'center' }}>
        <Paper elevation={3} sx={{ p: 3, minWidth: 700 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'row', flexGrow: 1 }}>
              <PersonPinCircleOutlined color="secondary" sx={{ alignSelf: 'center', mr: 4, width: 30, height: 30 }} />
              <Typography variant="h5" sx={{ alignSelf: 'center' }}>Basic Info</Typography>
              {editOpen && (
              <Box sx={{ alignSelf: 'center' }}>
                <Button
                  color="success"
                  variant="outlined"
                  size="small"
                  sx={{ ml: 2 }}
                  onClick={handleEditSave}
                >
                  Save
                </Button>
                <Button
                  color="error"
                  variant="outlined"
                  size="small"
                  sx={{ ml: 2 }}
                  onClick={handleEditClose}
                >
                  Cancel
                </Button>
              </Box>
              )}
              <IconButton size="large" sx={{ marginLeft: 'auto' }} onClick={handleMenuOpen}>
                <MoreVertOutlined />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleMenuClose}
                sx={{ ml: '-70px' }}
              >
                <MenuItem
                  sx={{ minWidth: 200 }}
                  onClick={handleEditOpen}
                >
                  Edit Info
                </MenuItem>
                {credential.auth0
                  ? (
                    [
                      <MenuItem
                        key="remove-login"
                        sx={{ minWidth: 200 }}
                        onClick={handleRemoveLogin}
                      >
                        Remove Login
                      </MenuItem>,
                      <MenuItem
                        key="reset-password"
                        sx={{ minWidth: 200 }}
                        onClick={handleResetPassword}
                      >
                        Reset Password
                      </MenuItem>,
                    ]
                  )
                  : (
                    <MenuItem
                      sx={{ minWidth: 200 }}
                      onClick={handleAddLogin}
                    >
                      Add Login
                    </MenuItem>
                  )}

                <MenuItem
                  sx={{ minWidth: 200 }}
                  onClick={handleDeleteLogin}
                >
                  <Typography color="error">Delete Credential</Typography>
                </MenuItem>
              </Menu>
            </Grid>
            <Grid item xs={12}>
              {editOpen
                ? (
                  <BasicInfoEdit
                    credential={credential}
                    formState={formState}
                    setFormState={setFormState}
                  />
                )
                : <BasicInfoList credential={credential} />}
            </Grid>
          </Grid>
        </Paper>
      </Box>

    </>
  );
}

export default InfoTab;
