import { useAuth0 } from '@auth0/auth0-react';

import Nav from '../nav/Nav';
import AppRouter from './AppRouter';
import LoginPage from './LoginPage';

function LoginHandler () {
  const { isAuthenticated } = useAuth0();

  return (isAuthenticated
    ? (
      <>
        <Nav />
        <AppRouter />
      </>
    )
    : <LoginPage />
  );
}

export default LoginHandler;
