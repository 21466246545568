import { useState, useEffect } from 'react';
import {
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Stack,
  Typography,
  Skeleton,
} from '@mui/material';

import useApi from '../../hooks/useApi';
import RoleSelect from './RoleSelect';

const ADMIN_SERVER_URL = process.env.REACT_APP_ADMIN_SERVER_URL;

const canHazOptions = ['Yes', 'No'];

function labelMapCanHaz (rawLabel) {
  if (rawLabel.includes('_')) {
    const label = rawLabel
      .split('_')
      // uppercase https://stackoverflow.com/questions/1026069/how-do-i-make-the-first-letter-of-a-string-uppercase-in-javascript
      .map((item) => item.charAt(0).toUpperCase() + item.slice(1))
      .join(' ');
    return label;
  }
  const label = rawLabel.charAt(0).toUpperCase() + rawLabel.slice(1);
  return label;
}

function InfoEdit ({ formState, setFormState, customer }) {
  const [customerOptions, setCustomerOptions] = useState(null);

  const [customers, customersLoading] = useApi({
    url: `${ADMIN_SERVER_URL}/v1/customer/all`,
    errorMessage: 'Shoot. I can\'t get them customers.',
    method: 'get',
  });

  const [whatTypeNameOptions, typeNameOptionsLoading] = useApi({
    url: `${ADMIN_SERVER_URL}/v1/permission/whatTypeNameOptions`,
    errorMessage: 'Shoot. I can\'t get them customers.',
    method: 'get',
  });

  useEffect(() => {
    if (customers) {
      const newCustomerOptions = customers.map(({ customerName, customerID }) => (
        { customerName, customerID }
      ));
      setCustomerOptions(newCustomerOptions);
    }
  }, [customers]);

  if (!formState) return null;

  function handleChange (e) {
    const { name, value } = e.target;

    if (name.includes('who-')) {
      const newFormState = {
        ...formState,
        who: {
          ...formState.who,
          [`${name.replace('who-', '')}`]: value,
        },
      };
      setFormState(newFormState);
    } else if (name.includes('canHaz-')) {
      const newLabel = name.replace('canHaz-', '');
      const canHazReplacementIndex = formState.canHaz.findIndex(({ label }) => label === newLabel);
      const newCanHaz = formState.canHaz.filter(({ label }) => label !== newLabel);
      newCanHaz.splice(canHazReplacementIndex, 0, {
        label: newLabel,
        data: value,
      });
      const newFormState = {
        ...formState,
        canHaz: newCanHaz,
      };
      setFormState(newFormState);
    } else {
      const newFormState = {
        ...formState,
        [name]: value,
      };
      setFormState(newFormState);
    }
  }

  function handleRoleChange (role) {
    const newFormState = {
      ...formState,
      who: {
        ...formState.who,
        role: role.replace('Add ', ''),
      },
    };
    setFormState(newFormState);
  }

  return (
    <Stack>
      <Typography variant="h6">Who</Typography>
      {Object.entries(formState?.who).map(([key, data]) => {
        if (key === 'customerID') {
          return customersLoading
            ? <Skeleton key={`skeleton-${key}`} variant="rectangular" width="auto" height={56} sx={{ mt: 1.5 }} />
            : (
              <FormControl variant="standard" margin="normal" fullWidth sx={{ mt: 1 }} key={`form-control-${key}`}>
                <InputLabel key={`input-label-${key}`}>Customer</InputLabel>
                <Select
                  onChange={handleChange}
                  value={customerOptions ? data : ''}
                  label="Customer"
                  name={`select-${key}`}
                >
                  {customerOptions?.map((option) => (
                    <MenuItem
                      key={`customer-${option.customerName}`}
                      value={option.customerID}
                    >
                      {option.customerName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            );
        }
        if (key === 'role') {
          return (
            <RoleSelect
              role={formState?.who.role}
              setRole={handleRoleChange}
              customerAccessID={customer?.customerAccessID}
              key={`role-select-${key}`}
            />
          );
        }
        return null;
      })}
      <Typography variant="h6" sx={{ mt: 4 }}>What</Typography>
      {
        typeNameOptionsLoading
          ? <Skeleton variant="rectangular" width="auto" height={56} sx={{ mt: 1.5 }} />
          : (
            <FormControl variant="standard" margin="normal" fullWidth sx={{ mt: 1 }}>
              <InputLabel>Type</InputLabel>
              <Select
                onChange={handleChange}
                value={whatTypeNameOptions ? formState?.what.typeName : ''}
                label="Type"
                name="what-role"
              >
                {whatTypeNameOptions?.map((option) => (
                  <MenuItem
                    key={`typeName-${option}`}
                    value={option}
                  >
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )
      }
      <Typography variant="h6" sx={{ mt: 4 }}>Can Haz</Typography>
      {formState?.canHaz.map(({ label, data }) => (
        <FormControl variant="standard" margin="normal" fullWidth sx={{ mt: 1 }}>
          <InputLabel key={label}>{labelMapCanHaz(label)}</InputLabel>
          <Select
            onChange={handleChange}
            value={data}
            label={labelMapCanHaz(label)}
            name={`canHaz-${label}`}
            key={`canHaz-${label}`}
          >
            {canHazOptions.map((option) => (
              <MenuItem
                key={`canHaz-${label}-${option}`}
                value={option}
              >
                {option}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      ))}
      <Typography variant="h6" sx={{ mt: 4 }}>Details</Typography>
      <TextField
        name="note"
        label="Note"
        margin="normal"
        onChange={handleChange}
        value={formState.note}
        multiline
        variant="standard"
      />
    </Stack>
  );
}

export default InfoEdit;
