import { useState, useEffect } from 'react';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Skeleton,
  Typography,
  Divider,
} from '@mui/material';

import useApi from '../../hooks/useApi';

import EditAccessFilterTable from '../credential-detail/EditAccessFilterTable';

const ADMIN_SERVER_URL = process.env.REACT_APP_ADMIN_SERVER_URL;

function SelectRoles ({ handleChange, formState, customerAccessID }) {
  const [defaultRoleOptions, setDefaultRoleOptions] = useState([]);
  const [permissions, loading] = useApi({
    url: `${ADMIN_SERVER_URL}/v1/permission/byCustomerAccessID/${customerAccessID}`,
    method: 'get',
    errorMessage: `Bob Sagat! Can't get permissions for ${customerAccessID}`,
  });

  useEffect(() => {
    if (permissions) {
      const roleOptions = permissions.map((permission) => permission.who.role);
      const removedDuplicates = [...new Set(roleOptions)];
      setDefaultRoleOptions(removedDuplicates);
    }
  }, [permissions]);

  const [boards, boardsLoading] = useApi({
    url: `${ADMIN_SERVER_URL}/v1/board/byCustomerAccessID/${customerAccessID}`,
    method: 'get',
    errorMessage: `Peanuts! Can't get boards for ${customerAccessID}`,
  });

  return (
    <>
      {
        loading
          ? <Skeleton variant="rectangular" width="auto" height={56} sx={{ mt: 2 }} />
          : (
            <FormControl margin="normal" fullWidth sx={{ mt: 2 }}>
              <InputLabel required>Default Role</InputLabel>
              <Select
                onChange={handleChange}
                value={formState.defaultRole}
                label="Default Role"
                name="defaultRole"
                required
              >
                {defaultRoleOptions?.map((option) => (
                  <MenuItem
                    key={`customer-${option.customerName}`}
                    value={option}
                  >
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )
      }
      {
        boardsLoading
          ? <Skeleton variant="rectangle" width="auto" height={200} sx={{ mt: 2 }} />
          : (
            <>
              <Typography sx={{ m: 2 }}>Access Filters</Typography>
              <Divider />
              <EditAccessFilterTable
                boards={boards}
                roleOptions={defaultRoleOptions}
                handleChange={handleChange}
              />
            </>
          )
      }
    </>
  );
}

export default SelectRoles;
