import { useEffect, useContext, useState } from 'react';
import {
  Box,
  Typography,
  Grid,
  Fab,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Skeleton,
} from '@mui/material';
import { Add } from '@mui/icons-material';

import { GlobalContext } from '../../context/dataContext';

import useApi from '../hooks/useApi';
import PermissionTable from './PermissionTable';
import NewPermissionDialog from './new-permission-dialog/NewPermissionDialog';

const ADMIN_SERVER_URL = process.env.REACT_APP_ADMIN_SERVER_URL;

function PermissionList () {
  const { setLoading } = useContext(GlobalContext);
  const [customerOptions, setCustomerOptions] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState('');

  const [customers, loading] = useApi({
    url: `${ADMIN_SERVER_URL}/v1/customer/all`,
    errorMessage: 'Shoot. I can\'t get them customers.',
    method: 'get',
  });

  useEffect(() => {
    if (customers) {
      const newCustomerOptions = customers.map(({ customerName, customerAccessID }) => (
        { customerName, customerAccessID }
      ));
      setCustomerOptions(newCustomerOptions);
      setSelectedCustomer(newCustomerOptions[0].customerAccessID);
    }
  }, [customers]);

  const [disableLoad, setDisableLoad] = useState(true);
  const [permissions, permissionsLoading, clearCache] = useApi({
    url: `${ADMIN_SERVER_URL}/v1/permission/byCustomerAccessID/${selectedCustomer}`,
    method: 'get',
    errorMessage: `Bob Sagat! Can't get permissions for ${selectedCustomer}`,
    disable: disableLoad,
  });

  useEffect(() => {
    if (loading || permissionsLoading) setLoading(true);
    if (!loading && !permissionsLoading) setLoading(false);
  }, [loading, permissionsLoading, setLoading]);

  useEffect(() => {
    if (selectedCustomer !== '') {
      setDisableLoad(false);
    }
  }, [selectedCustomer]);

  function handleNewClick (e) {
    e.preventDefault();
    setDialogOpen(true);
  }

  function handleSelect (e) {
    setSelectedCustomer(e.target.value);
  }

  function handleClose () {
    setDialogOpen(false);
  }

  return (
    <>
      <NewPermissionDialog
        isOpen={dialogOpen}
        setOpen={setDialogOpen}
        callbackCancel={handleClose}
        callbackConfirm={clearCache}
        customers={customers}
      />
      <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1, m: 2 }}>
        <Grid container spacing={2} sx={{ flexGrow: 1 }}>
          <Grid item xs={6}>
            <Typography variant="h3" sx={{ mt: 1 }}>
              Permissions
            </Typography>
          </Grid>
          <Grid item xs={2} />
          <Grid item xs={3}>
            {loading
              ? <Skeleton variant="rectangular" width="auto" height={56} sx={{ mt: 1.5 }} />
              : (
                <FormControl margin="normal" fullWidth sx={{ mt: 1 }}>
                  <InputLabel>Filter by Customer</InputLabel>
                  <Select
                    onChange={handleSelect}
                    value={selectedCustomer}
                    label="Filter by Customer"
                    name="filter"
                  >
                    {customerOptions?.map((option) => (
                      <MenuItem
                        key={`customer-${option.customerName}`}
                        value={option.customerAccessID}
                      >
                        {option.customerName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
          </Grid>
          <Grid item xs={1}>
            {loading
              ? <Skeleton variant="circular" width={56} height={56} sx={{ m: 1 }} />
              : (
                <Fab color="secondary" onClick={handleNewClick} sx={{ m: 1 }}>
                  <Add />
                </Fab>
              )}
          </Grid>
          <Grid item xs={12}>
            <PermissionTable permissions={permissions} height="70vh" loading={permissionsLoading || loading} />
          </Grid>
        </Grid>
      </Box>

    </>
  );
}

export default PermissionList;
