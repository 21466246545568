import { useContext } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { Box, Toolbar } from '@mui/material';

import { GlobalContext } from '../../context/dataContext';

import Home from '../home/Home';
import CustomerList from '../customer/CustomerList';
import CustomerDetail from '../customer/customer-detail/CustomerDetail';
import CredentialList from '../credential/CredentialList';
import CredentialDetail from '../credential/credential-detail/CredentialDetail';
import PermissionList from '../permission/PermissionList';
import PermissionDetail from '../permission/permission-detail/PermissionDetail';

function AppRouter () {
  const { navOpen } = useContext(GlobalContext);
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        m: 2,
        ml: navOpen ? '260px' : 4,
      }}
    >
      <Toolbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/customers" element={<CustomerList />} />
        <Route path="/customers/:customerAccessID" element={<CustomerDetail />} />
        <Route path="/credentials" element={<CredentialList />} />
        <Route path="/credentials/:email" element={<CredentialDetail />} />
        <Route path="/permissions" element={<PermissionList />} />
        <Route path="/permissions/:id" element={<PermissionDetail />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </Box>
  );
}

export default AppRouter;
