import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Avatar,
  Typography,
  IconButton,
  Stack,
  Chip,
  TextField,
  Button,
} from '@mui/material';
import { ArrowBackOutlined, Add } from '@mui/icons-material';
import { toast } from 'react-toastify';

import useCallApi from '../../hooks/useCallApi';

const ADMIN_SERVER_URL = process.env.REACT_APP_ADMIN_SERVER_URL;

function stringToColor (string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

function colorToSx (string) {
  return { bgcolor: stringToColor(string) };
}

function Image ({ credentialName }) {
  if (!credentialName) return null;
  const splitBySpace = credentialName.split(' ');

  const firstInital = splitBySpace[0].charAt(0).toUpperCase();
  const secondInitial = splitBySpace[1]?.charAt(0).toUpperCase();
  const initials = `${firstInital}${secondInitial || ''}`;

  return (
    <Avatar
      alt={credentialName}
      sx={{
        ...colorToSx(credentialName),
        m: 4,
        width: 180,
        height: 180,
      }}
    >
      {initials}
    </Avatar>
  );
}

function CredentialDetailHeader ({ credential }) {
  const [localTags, setLocalTags] = useState([]);
  const [newTagEntry, setNewTagEntry] = useState(false);
  const [dirtyTag, setDirtyTag] = useState('');

  useEffect(() => {
    if (credential) {
      const newTags = credential?.champ?.tags;
      setLocalTags(newTags);
    }
  }, [credential]);

  const navigate = useNavigate();

  const updateTags = useCallApi({
    url: `${ADMIN_SERVER_URL}/v1/credential/noLogin`,
    method: 'put',
  });

  if (!credential) return null;
  const { firstName, lastName, email, title } = credential.champ;

  const name = firstName && `${firstName} ${lastName}`;

  function handleClick () {
    navigate('../credentials');
  }

  async function handleTagDelete (i) {
    const newTags = [...localTags];
    newTags.splice(i, 1);
    setLocalTags(newTags);

    const newCredential = {
      ...credential.champ,
      tags: newTags,
    };

    await toast.promise(
      updateTags({ body: newCredential }),
      {
        pending: 'Saving tags',
        error: 'Your tag has been rejected due to unspecified reasons',
        success: 'Tag updated',
      },
    );
  }

  function handleNewTag () {
    setNewTagEntry(true);
  }

  function handleNewTagChange (e) {
    setDirtyTag(e.target.value);
  }

  async function handleAddTag () {
    let newTags = [];
    if (localTags?.length > 0) {
      newTags = [...localTags, dirtyTag];
    } else {
      newTags = [dirtyTag];
    }

    const newCredential = {
      ...credential.champ,
      tags: newTags,
    };
    setLocalTags(newTags);
    setNewTagEntry(false);
    setDirtyTag('');

    await toast.promise(
      updateTags({ body: newCredential }),
      {
        pending: 'Saving tags',
        error: 'Your tag has been rejected due to unspecified reasons',
        success: 'Tag updated',
      },
    );
  }

  function handleCancelTag () {
    setDirtyTag('');
    setNewTagEntry(false);
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', flexGrow: 1, alignItems: 'center' }}>
      <IconButton size="large" sx={{ alignSelf: 'start' }} onClick={handleClick}>
        <ArrowBackOutlined />
      </IconButton>
      <Image credentialName={name || email} />
      <Stack>
        <Typography variant="h4" sx={{ mb: 1 }}>{name || email}</Typography>
        <Typography variant="subtitle2" sx={{ mb: 1 }}>{title || 'No Title'}</Typography>
        <Stack direction="row">
          {localTags?.map((tag, i) => (
            <Chip
              key={tag}
              label={tag}
              variant="outlined"
              color="secondary"
              sx={{ mr: 1, mt: 0.5 }}
              onDelete={() => handleTagDelete(i)}
            />
          ))}
          {newTagEntry
            ? (
              <>
                <TextField
                  label="Enter Tag"
                  size="small"
                  value={dirtyTag}
                  onChange={handleNewTagChange}
                  sx={{ ml: 1 }}
                />
                <Button onClick={handleAddTag} color="success" sx={{ ml: 1 }}>Save</Button>
                <Button onClick={handleCancelTag} color="error" sx={{ ml: 1 }}>Cancel</Button>
              </>
            )
            : (
              <IconButton onClick={handleNewTag} variant="outlined">
                <Add />
              </IconButton>
            )}
        </Stack>
      </Stack>
    </Box>
  );
}

export default CredentialDetailHeader;
