import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';

const AUTH_AUDIENCE = process.env.REACT_APP_AUTH_AUDIENCE || 'com-champds-adminserver-dev';

function useCallApi ({ url, method }) {
  const { getAccessTokenSilently, user } = useAuth0();

  async function callApi ({ body }) {
    try {
      const token = await getAccessTokenSilently({
        audience: AUTH_AUDIENCE,
        scope: 'crud:admin openid',
      });

      const config = {
        method,
        headers: {
          Authorization: `Bearer ${token}`,
          'cds-remoteid': user.email,
        },
        data: body,
      };
      const result = await axios(url, config);

      return result.data;
    } catch (e) {
      console.log(e);
      throw e;
    }
  }

  return callApi;
}

export default useCallApi;
