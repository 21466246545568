import {
  Grid,
  List,
  ListItem,
  Typography,
} from '@mui/material';

function LabelData ({ label, data }) {
  return (
    <Grid container spacing={2}>
      <Grid item xs={4}>
        <Typography variant="overline">{label}</Typography>
      </Grid>
      <Grid item xs={8}>
        <Typography variant="subtitle1">{data}</Typography>
      </Grid>
    </Grid>
  );
}

const labelMap = {
  email: 'Email',
  title: 'Title',
  firstName: 'First Name',
  lastName: 'Last Name',
  pronouns: 'Pronouns',
  phone: 'Phone',
  login: 'Login',
  defaultRole: 'Default Role',
  note: 'Note',
};

function BasicInfoList ({ credential }) {
  if (!credential) return null;

  return (
    <List>
      {Object.entries(labelMap).map(([key, value]) => {
        if (key === 'login') {
          return (
            <ListItem key={`list-item-${key}`}>
              <LabelData key={`label-data-${key}`} label={value} data={credential.auth0?.email || 'No Login'} />
            </ListItem>
          );
        }
        return (
          <ListItem key={`list-item-${key}`}>
            <LabelData key={`label-data-${key}`} label={value} data={credential.champ[key]} />
          </ListItem>
        );
      })}
    </List>
  );
}

export default BasicInfoList;
