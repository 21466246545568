import { useEffect, useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Paper,
  Box,
} from '@mui/material';

import { GlobalContext } from '../../../context/dataContext';

import useApi from '../../hooks/useApi';

import PermissionDetailSkeleton from './PermissionDetailSkeleton';
import PermissionHeader from './PermissionHeader';
import PermissionInfo from './PermissionInfo';

const ADMIN_SERVER_URL = process.env.REACT_APP_ADMIN_SERVER_URL;

function PermissionDetail () {
  const { id } = useParams();
  const { setLoading } = useContext(GlobalContext);
  const [disableCustomerLoad, setDisableCustomerLoad] = useState(true);

  const [permission, permissionLoading] = useApi({
    url: `${ADMIN_SERVER_URL}/v1/permission/byID/${id}`,
    method: 'get',
    errorMessage: `Unable to acquire requested permission.`,
  });

  const [customer, customerLoading] = useApi({
    url: `${ADMIN_SERVER_URL}/v1/customer/byID/${permission?.who.customerID}`,
    method: 'get',
    errorMessage: 'Unable to get individual customer. I blame Cox',
    disable: disableCustomerLoad,
  });

  useEffect(() => {
    if (customerLoading || permissionLoading) setLoading(true);
    if (!customerLoading && !permissionLoading) setLoading(false);
  }, [customerLoading, permissionLoading, setLoading]);

  useEffect(() => {
    if (permission && !permissionLoading) setDisableCustomerLoad(false);
  }, [permission, permissionLoading]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        m: 2,
        height: '100%',
      }}
    >
      <Paper sx={{ flexGrow: 1, p: 4, display: 'flex', flexDirection: 'column' }}>
        {permissionLoading || customerLoading
          ? <PermissionDetailSkeleton />
          : (
            <>
              <PermissionHeader permission={permission} customer={customer} />
              <PermissionInfo permission={permission} customer={customer} />
            </>
          )}
      </Paper>
    </Box>
  );
}

export default PermissionDetail;
