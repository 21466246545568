import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import {
  TextField,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  Typography,
  FormGroup,
  FormControlLabel,
  Switch,
} from '@mui/material';

import useCallApi from '../../hooks/useCallApi';

import SelectRoles from './SelectDefaultRole';

const ADMIN_SERVER_URL = process.env.REACT_APP_ADMIN_SERVER_URL;

const fixedFields = [
  {
    name: 'email',
    label: 'Email',
  },
  {
    name: 'firstName',
    label: 'First Name',
  },
  {
    name: 'lastName',
    label: 'Last Name',
  },
  {
    name: 'pronouns',
    label: 'Pronouns',
  },
  {
    name: 'title',
    label: 'Title',
  },
  {
    name: 'phone',
    label: 'Phone',
  },
];

function validateEmail (string) {
  return String(string)
    .toLowerCase()
    // eslint-disable-next-line no-control-regex
    .match(/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/);
}

const formDefault = {
  email: '',
  firstName: '',
  lastName: '',
  pronouns: '',
  phone: '',
  title: '',
  customerAccessID: '',
  defaultRole: '',
  createLogin: false,
};

const errorDefault = {
  email: false,
  firstName: false,
  lastName: false,
  pronouns: false,
  customerAccessID: false,
  defaultRole: false,
};

function NewCredentialDialog ({ isOpen, setOpen, callbackCancel, callbackConfirm, customerOptions }) {
  const [formState, setFormState] = useState(formDefault);
  const [errorState, setErrorState] = useState(errorDefault);
  const [newCustomerAccessID, setNewCustomerAccessID] = useState(null);

  useEffect(() => {
    if (!isOpen) {
      setFormState(formDefault);
      setErrorState(errorDefault);
      setNewCustomerAccessID(null);
    }
  }, [isOpen]);

  function handleChange (e) {
    const { name, value } = e.target;

    const newFormState = {
      ...formState,
      [name]: value,
    };
    setFormState(newFormState);
    if (name === 'customerAccessID') setNewCustomerAccessID(value);
    if (value === '' && name !== 'email') {
      const newErrorState = {
        ...errorState,
        [name]: true,
      };
      setErrorState(newErrorState);
    }
    if (value !== '' && name !== 'email') {
      const newErrorState = {
        ...errorState,
        [e.target.name]: false,
      };
      setErrorState(newErrorState);
    }
    if (name === 'email' && validateEmail(value)) {
      const newErrorState = {
        ...errorState,
        [name]: false,
      };
      setErrorState(newErrorState);
    }
    if (name === 'email' && !validateEmail(value)) {
      const newErrorState = {
        ...errorState,
        [name]: true,
      };
      setErrorState(newErrorState);
    }
  }

  function handleSwitch (e) {
    const newFormState = {
      ...formState,
      createLogin: e.target.checked,
    };
    setFormState(newFormState);
  }

  function handleBlur (e) {
    if (formState[e.target.name] === '') {
      const newErrorState = {
        ...errorState,
        [e.target.name]: true,
      };
      setErrorState(newErrorState);
    }

    if (formState[e.target.name] !== '') {
      const newErrorState = {
        ...errorState,
        [e.target.name]: false,
      };
      setErrorState(newErrorState);
    }
  }

  const newCredentialWithLogin = useCallApi({
    url: `${ADMIN_SERVER_URL}/v1/credential`,
    method: 'post',
  });

  const newCredentialNoLogin = useCallApi({
    url: `${ADMIN_SERVER_URL}/v1/credential/noLogin`,
    method: 'post',
  });

  async function handleConfirm (e) {
    e.preventDefault();
    if (Object.values(errorState).some((value) => value)) {
      toast.error('Form invalid.');
      return;
    }
    if (Object.entries(formState).some(([key, value]) => {
      if (key === 'createLogin') return false;
      return value === formDefault[key];
    })) {
      toast.error('Please enter info into all the fields with *. I can\'t save blank.....');
      return;
    }
    setOpen(false);

    const dataToSubmit = { accessFilter: { board: [] } };

    Object.entries(formState).forEach(([key, value]) => {
      if (key.includes('board-')) {
        const boardID = parseInt(key.replace('board-', ''), 10);
        dataToSubmit.accessFilter.board.push({ id: boardID, role: value });
      } else {
        dataToSubmit[key] = value;
      }
    });

    if (formState.createLogin) {
      await toast.promise(
        newCredentialWithLogin({ body: dataToSubmit }),
        {
          pending: 'Creating new credential. This may take a minute.',
          success: 'woot. woot. credential was created!',
          error: 'Awe hell. Credential creation not successful. Try again? Call John?',
        },
      );
    } else {
      await toast.promise(
        newCredentialNoLogin({ body: dataToSubmit }),
        {
          pending: 'Creating new credential. This may take a minute.',
          success: 'woot. woot. credential was created!',
          error: 'Awe hell. Credential creation not successful. Try again? Call John?',
        },
      );
    }

    callbackConfirm();
  }

  return (
    <Dialog
      open={isOpen}
      onClose={() => setOpen(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <Box sx={{ p: 3 }}>
        <DialogTitle id="alert-dialog-title" sx={{ width: '500px' }}>
          Create New Credential
        </DialogTitle>
        <DialogContent>
          <Stack sx={{ flexGrow: 1 }}>
            <Typography color="yellow">All fields with * are required.</Typography>
            <FormGroup>
              <FormControlLabel
                control={<Switch checked={formState.createLogin} onChange={handleSwitch} />}
                name="ONOFF"
                label="Create Login?"
                sx={{ mt: 2 }}
                color="success"
              />
            </FormGroup>

            {fixedFields.map((field) => (
              <TextField
                key={field.name}
                name={field.name}
                label={field.label}
                error={errorState[field.name]}
                margin="normal"
                onChange={handleChange}
                value={formState[field.name]}
                required
                onBlur={handleBlur}
              />
            ))}
            <FormControl margin="normal" fullWidth sx={{ mt: 2 }}>
              <InputLabel required>Customer</InputLabel>
              <Select
                onChange={handleChange}
                value={formState.customerAccessID}
                label="Customer"
                name="customerAccessID"
                required
              >
                {customerOptions?.map((option) => (
                  <MenuItem
                    key={`customer-${option.customerName}`}
                    value={option.customerAccessID}
                  >
                    {option.customerName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {newCustomerAccessID && (
            <SelectRoles
              handleChange={handleChange}
              customerAccessID={newCustomerAccessID}
              formState={formState}
            />
            )}
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={callbackCancel}>Cancel</Button>
          <Button onClick={handleConfirm} autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Box>

    </Dialog>
  );
}

export default NewCredentialDialog;
