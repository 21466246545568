import { Skeleton, Box, Grid, Stack } from '@mui/material';

function PermissionDetailSkeleton () {
  return (
    <Box>
      <Grid container>
        <Grid item xs={12} sx={{ display: 'flex', mt: 2 }}>
          <Skeleton variant="circular" width={50} height={50} sx={{ alignSelf: 'start' }} />
          <Stack sx={{ ml: 4 }}>
            <Skeleton variant="text" width={400} height={80} sx={{ alignSelf: 'center', m: 1 }} />
          </Stack>
        </Grid>
        <Grid item xs={12} sx={{ mt: 4 }}>
          <Skeleton variant="rectangle" height={500} />
        </Grid>
      </Grid>
    </Box>
  );
}

export default PermissionDetailSkeleton;
