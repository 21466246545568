import { useEffect, useContext, useState } from 'react';
import {
  Box,
  Typography,
  Grid,
  Fab,
} from '@mui/material';
import { Add } from '@mui/icons-material';

import { GlobalContext } from '../../context/dataContext';

import useApi from '../hooks/useApi';
import CustomerTable from './CustomerTable';
import NewCustomerDialog from './NewCustomerDialog';

const ADMIN_SERVER_URL = process.env.REACT_APP_ADMIN_SERVER_URL;

function CustomerList () {
  const [dialogOpen, setDialogOpen] = useState(false);
  const { setLoading } = useContext(GlobalContext);

  const [customers, loading, clearCache] = useApi({
    url: `${ADMIN_SERVER_URL}/v1/customer/all`,
    errorMessage: 'Shoot. I can\'t get them customers.',
    method: 'get',
  });

  useEffect(() => {
    setLoading(loading);
  }, [loading, setLoading]);

  function handleNewClick (e) {
    e.preventDefault();
    setDialogOpen(true);
  }

  function handleClose () {
    setDialogOpen(false);
  }

  return (
    <>
      <NewCustomerDialog
        isOpen={dialogOpen}
        setOpen={setDialogOpen}
        callbackCancel={handleClose}
        callbackConfirm={clearCache}
      />
      <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1, m: 2 }}>
        <Grid container spacing={2} sx={{ flexGrow: 1 }}>
          <Grid item xs={11}>
            <Typography variant="h3">
              Customers
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <Fab color="secondary" onClick={handleNewClick}>
              <Add />
            </Fab>
          </Grid>
          <Grid item xs={12}>
            <CustomerTable customers={customers} loading={loading} />
          </Grid>
        </Grid>
      </Box>

    </>
  );
}

export default CustomerList;
