import { useNavigate } from 'react-router-dom';
import {
  Box,
  Typography,
  IconButton,
} from '@mui/material';
import { ArrowBackOutlined } from '@mui/icons-material';

function PermissionHeader ({ permission, customer }) {
  const navigate = useNavigate();

  if (!permission || !customer) return null;

  function handleClick () {
    navigate('../permissions');
  }

  const { role } = permission.who;
  const { typeName } = permission.what;

  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', flexGrow: 1, alignItems: 'center' }}>
      <IconButton size="large" sx={{ alignSelf: 'start' }} onClick={handleClick}>
        <ArrowBackOutlined />
      </IconButton>
      <Typography variant="h4" sx={{ mb: 1, ml: 4 }}>{`${role} - ${typeName} - Customer ${customer.customerName}`}</Typography>
    </Box>
  );
}

export default PermissionHeader;
