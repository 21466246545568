import { useAuth0 } from '@auth0/auth0-react';
import {
  Box,
  Typography,
  Stack,
  Button,
  Toolbar,
  Paper,
} from '@mui/material';

function LoginPage () {
  const { loginWithRedirect } = useAuth0();
  return (
    <Box sx={{ m: 2, display: 'flex', flexGrow: 1, flexDirection: 'column', alignItems: 'center' }}>
      <Toolbar />
      <Paper elevation={1} sx={{ m: 1, p: 10 }}>
        <Stack spacing={4} sx={{ alignItems: 'center' }}>
          <Typography variant="h3">
            Login
          </Typography>
          <Button
            color="secondary"
            variant="outlined"
            onClick={loginWithRedirect}
            sx={{ width: '20em' }}
          >
            Login
          </Button>
        </Stack>
      </Paper>
    </Box>
  );
}

export default LoginPage;
