/* eslint-disable no-underscore-dangle */
import { useState, useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Button, Avatar } from '@mui/material';
import { useNavigate } from 'react-router-dom';

function stringToColor (string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

function colorToSx (string) {
  return { bgcolor: stringToColor(string) };
}

function Image (props) {
  const { row: { firstName, lastName, deviceNickName } } = props;

  if (deviceNickName) {
    const splitBySpace = deviceNickName.split(' ');
    const firstInital = splitBySpace[0].charAt(0).toUpperCase();
    const secondInitial = splitBySpace[1]?.charAt(0).toUpperCase();
    const initials = `${firstInital}${secondInitial || ''}`;
    return <Avatar alt={`${deviceNickName}`} sx={colorToSx(`${deviceNickName}`)}>{initials}</Avatar>;
  }

  const firstInital = firstName?.charAt(0).toUpperCase();
  const secondInitial = lastName?.charAt(0).toUpperCase();
  const initials = `${firstInital}${secondInitial || ''}`;

  return (
    <Avatar alt={`${firstName} ${lastName}`} sx={colorToSx(`${firstName}${lastName}`)}>{initials}</Avatar>
  );
}

function DetailButton (props) {
  const navigate = useNavigate();

  const { row: { email } } = props;

  function handleClick (e) {
    e.preventDefault();
    navigate(`../credentials/${email}`);
  }
  return (
    <Button onClick={handleClick}>Detail</Button>
  );
}

const columns = [
  { field: 'image', headerName: '', width: 80, renderCell: Image, hideable: false, filterable: false, sortable: false },
  { field: 'email', headerName: 'Email', flex: 2, hideable: false },
  { field: 'firstName', headerName: 'First Name', flex: 1, hideable: false },
  { field: 'lastName', headerName: 'Last Name', flex: 1, hideable: false },
  { field: 'defaultRole', headerName: 'Default Role', flex: 1, hideable: false },
  { field: 'customerAccessID', headerName: 'Customer Access ID', flex: 1, hideable: false },
  { field: 'deviceNickName', headerName: 'Device Name', flex: 1, hideable: false },
  { field: 'detail', headerName: '', width: 150, renderCell: DetailButton, filterable: false, sortable: false, hideable: false },
];

function CredentialTable ({ credentials, height = '70vh', loading = false }) {
  const [rows, setRows] = useState([]);

  useEffect(() => {
    if (credentials) {
      const newRows = credentials.map(({ champ }) => ({
        id: champ._id,
        email: champ.email,
        firstName: champ?.firstName,
        lastName: champ?.lastName,
        defaultRole: champ.defaultRole,
        customerAccessID: champ.customerAccessID,
        deviceNickName: champ?.deviceNickName,
      }));
      setRows(newRows);
    }
  }, [credentials]);

  return (
    <div style={{ height, width: '100%' }}>
      <DataGrid
        rows={rows}
        columns={columns}
        sx={{ border: 'none' }}
        loading={loading}
        autoPageSize
      />
    </div>
  );
}

export default CredentialTable;
