import { useNavigate } from 'react-router-dom';
import {
  Box,
  Avatar,
  Typography,
  IconButton,
} from '@mui/material';
import { ArrowBackOutlined } from '@mui/icons-material';

function stringToColor (string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

function colorToSx (string) {
  return { bgcolor: stringToColor(string) };
}

function Image ({ customerName }) {
  if (!customerName) return null;
  const splitBySpace = customerName.split(' ');

  const firstInital = splitBySpace[0].charAt(0).toUpperCase();
  const secondInitial = splitBySpace[1]?.charAt(0).toUpperCase();
  const initials = `${firstInital}${secondInitial || ''}`;

  return (
    <Avatar
      alt={customerName}
      sx={{
        ...colorToSx(customerName),
        m: 4,
        width: 180,
        height: 180,
      }}
    >
      {initials}
    </Avatar>
  );
}

function CustomerDetailHeader ({ customer }) {
  const navigate = useNavigate();
  if (!customer) return null;
  const { customerName } = customer;

  function handleClick () {
    navigate('../customers');
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', flexGrow: 1, alignItems: 'center' }}>
      <IconButton size="large" sx={{ alignSelf: 'start' }} onClick={handleClick}>
        <ArrowBackOutlined />
      </IconButton>
      <Image customerName={customerName} />
      <Typography variant="h4">{customerName}</Typography>
    </Box>
  );
}

export default CustomerDetailHeader;
