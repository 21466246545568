/* eslint-disable react/jsx-no-bind */
import { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { Skeleton } from '@mui/material';

import useApi from '../../hooks/useApi';

const filter = createFilterOptions();

const ADMIN_SERVER_URL = process.env.REACT_APP_ADMIN_SERVER_URL;

function RoleSelect ({ role, setRole, customerAccessID, variant = 'standard' }) {
  const [defaultRoleOptions, setDefaultRoleOptions] = useState([]);
  const [permissions, loading] = useApi({
    url: `${ADMIN_SERVER_URL}/v1/permission/byCustomerAccessID/${customerAccessID}`,
    method: 'get',
    errorMessage: `Bob Sagat! Can't get permissions for ${customerAccessID}`,
  });

  useEffect(() => {
    if (permissions) {
      const roleOptions = permissions.map((permission) => permission.who.role);
      const removedDuplicates = [...new Set(roleOptions)];
      setDefaultRoleOptions(removedDuplicates);
    }
  }, [permissions]);

  if ((!role && role !== '') || !setRole || !customerAccessID) return null;

  return loading
    ? <Skeleton variant="rectangular" width="auto" height={56} sx={{ mt: 2 }} />
    : (
      // https://mui.com/material-ui/react-autocomplete/#creatable
      <Autocomplete
        value={role}
        onChange={(event, newValue) => {
          if (typeof newValue === 'string') {
            setRole(newValue);
          } else if (newValue && newValue.inputValue) {
          // Create a new value from the user input
            setRole(newValue.inputValue.replace('Add ', ''));
          } else {
            setRole(newValue);
          }
        }}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);

          const { inputValue } = params;
          // Suggest the creation of a new value
          const isExisting = options.some((option) => inputValue === option);
          if (inputValue !== '' && !isExisting) {
            filtered.push(`Add ${inputValue}`);
          }
          return filtered;
        }}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        options={defaultRoleOptions}
        getOptionLabel={(option) => {
        // Value selected with enter, right from the input
          if (typeof option === 'string') {
            return option;
          }
          // Add "xxx" option created dynamically
          if (option.inputValue) {
            return option.inputValue;
          }
          // Regular option
          return option;
        }}
      // eslint-disable-next-line react/jsx-props-no-spreading
        renderOption={(props, option) => <li {...props}>{option}</li>}
        sx={{ width: 'auto', mt: 2 }}
        freeSolo
        renderInput={(params) => (
        // eslint-disable-next-line react/jsx-props-no-spreading
          <TextField {...params} label="Role" variant={variant} />
        )}
      />
    );
}

export default RoleSelect;
