import { useEffect, useContext, useState } from 'react';
import {
  Box,
  Typography,
  Grid,
  Fab,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Skeleton,
} from '@mui/material';
import { Add } from '@mui/icons-material';

import { GlobalContext } from '../../context/dataContext';

import useApi from '../hooks/useApi';
import CredentialTable from './CredentialTable';
import NewCredentialDialog from './new-credential-dialog/NewCredentialDialog';

const ADMIN_SERVER_URL = process.env.REACT_APP_ADMIN_SERVER_URL;

function CredentialList () {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [customerOptions, setCustomerOptions] = useState(null);
  const [selectedCustomer, setSelectedCustomer] = useState('');
  const { setLoading } = useContext(GlobalContext);

  const [credentials, loading, clearCache] = useApi({
    url: `${ADMIN_SERVER_URL}/v1/credential/${selectedCustomer === '' ? 'all' : `byAccessID/${selectedCustomer}`}`,
    errorMessage: 'Oh dear. We seem to be able to retrieve the credentials.',
    method: 'get',
  });

  const [customers, customersLoading] = useApi({
    url: `${ADMIN_SERVER_URL}/v1/customer/all`,
    errorMessage: 'Shoot. I can\'t get them customers.',
    method: 'get',
  });

  useEffect(() => {
    setLoading(loading);
  }, [loading, setLoading]);

  useEffect(() => {
    if (customers) {
      const newCustomerOptions = customers.map(({ customerName, customerAccessID }) => (
        { customerName, customerAccessID }
      ));
      setCustomerOptions(newCustomerOptions);
    }
  }, [customers]);

  function handleNewClick (e) {
    e.preventDefault();
    setDialogOpen(true);
  }

  function handleClose () {
    setDialogOpen(false);
  }

  function handleSelect (e) {
    setSelectedCustomer(e.target.value);
  }

  return (
    <>
      <NewCredentialDialog
        isOpen={dialogOpen}
        setOpen={setDialogOpen}
        callbackCancel={handleClose}
        callbackConfirm={clearCache}
        customerOptions={customerOptions}
      />
      <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1, m: 2 }}>
        <Grid container spacing={2} sx={{ flexGrow: 1 }}>
          <Grid item xs={6}>
            <Typography variant="h3" sx={{ mt: 1 }}>
              Credentials
            </Typography>
          </Grid>
          <Grid item xs={2} />
          <Grid item xs={3}>
            {customersLoading
              ? <Skeleton variant="rectangular" width="auto" height={56} sx={{ mt: 1.5 }} />
              : (
                <FormControl margin="normal" fullWidth sx={{ mt: 1 }}>
                  <InputLabel>Filter by Customer</InputLabel>
                  <Select
                    onChange={handleSelect}
                    value={selectedCustomer}
                    label="Filter by Customer"
                    name="filter"
                  >
                    {customerOptions?.map((option) => (
                      <MenuItem
                        key={`customer-${option.customerName}`}
                        value={option.customerAccessID}
                      >
                        {option.customerName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
          </Grid>
          <Grid item xs={1}>
            {customersLoading
              ? <Skeleton variant="circular" width={56} height={56} sx={{ m: 1 }} />
              : (
                <Fab color="secondary" onClick={handleNewClick} sx={{ m: 1 }}>
                  <Add />
                </Fab>
              )}
          </Grid>
          <Grid item xs={12}>
            <CredentialTable credentials={credentials} loading={loading} />
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default CredentialList;
