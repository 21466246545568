import { createContext, useState, useMemo } from 'react';

export const GlobalContext = createContext();

export function GlobalContextProvider (props) {
  const { children } = props;

  const [navOpen, setNavOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const globalContext = useMemo(() => ({
    navOpen,
    setNavOpen,
    loading,
    setLoading,
    toggleNav () {
      setNavOpen(!navOpen);
    },
  }), [loading, navOpen]);

  return <GlobalContext.Provider value={globalContext}>{children}</GlobalContext.Provider>;
}
