import { useState, useEffect } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';

function AccessFilterTable ({ boards, accessFilter }) {
  const [boardAndRole, setBoardAndRole] = useState([]);

  useEffect(() => {
    if (boards && accessFilter.board) {
      const newBoardAndRole = boards.map(({ customerBoardID, boardName }) => {
        const filter = accessFilter.board.find(({ id }) => customerBoardID === id);
        return { role: filter?.role || 'no data', name: boardName };
      });
      setBoardAndRole(newBoardAndRole);
    }
  }, [accessFilter, boards]);
  if (!boards || !accessFilter) return null;

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell padding="normal">Board</TableCell>
            <TableCell padding="normal" sx={{ width: '50%' }}>Role</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {boardAndRole?.map(({ role, name }) => (
            <TableRow key={`${role}-${name}`}>
              <TableCell>{name}</TableCell>
              <TableCell>{role}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default AccessFilterTable;
