import { useContext } from 'react';
import {
  Drawer,
  List,
  Toolbar,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Divider,
  Box,
} from '@mui/material';
import {
  AccountCircleOutlined,
  SwitchAccountOutlined,
  DangerousOutlined,
  HomeMaxOutlined,
} from '@mui/icons-material';
import { Link } from 'react-router-dom';

import { GlobalContext } from '../../context/dataContext';

const navItems = [
  {
    url: '/',
    name: 'Home',
    icon: <HomeMaxOutlined />,
  },
  {
    url: '/customers',
    name: 'Customers',
    icon: <AccountCircleOutlined />,
  },
  {
    url: '/credentials',
    name: 'Credentials',
    icon: <SwitchAccountOutlined />,
  },
  {
    url: '/permissions',
    name: 'Permissions',
    icon: <DangerousOutlined />,
  },
];

function Nav () {
  const { navOpen } = useContext(GlobalContext);

  return (
    <Drawer
      variant="persistent"
      sx={{
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: { width: 240, boxSizing: 'border-box' },
      }}
      open={navOpen}
    >
      <Toolbar />
      <List sx={{ alignItems: 'center' }}>
        {navItems.map((item) => (
          <Box key={item.url}>
            <ListItem disablePadding>
              <ListItemButton component={Link} to={item.url}>
                <ListItemIcon>
                  {item.icon}
                </ListItemIcon>
                <ListItemText primary={item.name} />
              </ListItemButton>
            </ListItem>
            <Divider />
          </Box>
        ))}
      </List>
    </Drawer>
  );
}

export default Nav;
