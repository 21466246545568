import { useState, useEffect } from 'react';
import {
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Stack,
  Skeleton,
} from '@mui/material';

import useApi from '../../hooks/useApi';

const ADMIN_SERVER_URL = process.env.REACT_APP_ADMIN_SERVER_URL;

const labelMap = {
  email: {
    label: 'Email',
    type: 'field',
  },
  title: {
    label: 'Title',
    type: 'field',
  },
  firstName: {
    label: 'First Name',
    type: 'field',
  },
  lastName: {
    label: 'Last Name',
    type: 'field',
  },
  pronouns: {
    label: 'Pronouns',
    type: 'field',
  },
  phone: {
    label: 'Phone',
    type: 'field',
  },
  defaultRole: {
    label: 'Default Role',
    type: 'select',
  },
  note: {
    label: 'Note',
    type: 'multiline',
  },
};

function BasicInfoEdit ({ credential, formState, setFormState }) {
  const [defaultRoleOptions, setDefaultRoleOptions] = useState([]);
  const [permissions, loading] = useApi({
    url: `${ADMIN_SERVER_URL}/v1/permission/byCustomerAccessID/${credential.champ.customerAccessID}`,
    method: 'get',
    errorMessage: `Bob Sagat! Can't get permissions for ${credential.champ.customerAccessID}`,
  });

  useEffect(() => {
    if (permissions) {
      const roleOptions = permissions.map((permission) => permission.who.role);
      const removedDuplicates = [...new Set(roleOptions)];
      setDefaultRoleOptions(removedDuplicates);
    }
  }, [permissions]);

  if (!credential) return null;

  function handleChange (e) {
    const newFormState = {
      ...formState,
      [e.target.name]: e.target.value,
    };
    setFormState(newFormState);
  }

  return (
    <Stack>
      {Object.keys(labelMap).map((key) => {
        if (!credential.champ[key]) {
          return (
            <TextField
              key={labelMap[key].label}
              name={key}
              label={labelMap[key].label}
              margin="normal"
              onChange={handleChange}
              value={formState[key]}
            />
          );
        }
        if (labelMap[key].type === 'field') {
          return (
            <TextField
              key={labelMap[key].label}
              name={key}
              label={labelMap[key].label}
              margin="normal"
              onChange={handleChange}
              value={formState[key]}
            />
          );
        }
        if (key === 'defaultRole') {
          return loading
            ? <Skeleton key={key} variant="rectangular" width="auto" height={56} sx={{ mt: 2 }} />
            : (
              <FormControl key={key} margin="normal" fullWidth sx={{ mt: 2 }}>
                <InputLabel required>Default Role</InputLabel>
                <Select
                  onChange={handleChange}
                  value={defaultRoleOptions.length > 0 ? formState.defaultRole : ''}
                  label="Default Role"
                  name="defaultRole"
                  required
                >
                  {defaultRoleOptions.map((option) => (
                    <MenuItem
                      key={`customer-${option.customerName}`}
                      value={option}
                    >
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            );
        }
        if (labelMap[key].type === 'multiline') {
          return (
            <TextField
              key={labelMap[key].label}
              name={key}
              label={labelMap[key].label}
              margin="normal"
              onChange={handleChange}
              value={formState[key]}
              multiline
            />
          );
        }
        return null;
      })}
    </Stack>
  );
}

export default BasicInfoEdit;
